export const ClassFilterConfig = [
    {
      fieldGroupClassName: 'display-flex',
      fieldGroup: [
        {
          key: 'courseCode',
          type: 'tdoe-input',
          props: {
            label: 'Course Code'
          },
          className: 'course-code-field'
        },
        {
          key: 'localClassNumber',
          type: 'tdoe-input',
          props: {
            label: 'Local Class Number'
          },
          className: 'local-class-number-field'
        },
        {
          key: 'sectionIdentifier',
          type: 'tdoe-select',
          props: {
            label: 'Section Identifier',
            options: [
              {
                label: '01',
                value: '01'
              },
              {
                label: '02',
                value: '02'
              },
              {
                label: '03',
                value: '03'
              },
              {
                label: '04',
                value: '04'
              },
              {
                label: '05',
                value: '05'
              },
              {
                label: '06',
                value: '06'
              },
              {
                label: '07',
                value: '07'
              },
              {
                label: '08',
                value: '08'
              },
              {
                label: '09',
                value: '09'
              },
              {
                label: '10',
                value: '10'
              }
            ],
            multiple: true
          },
          className: 'section-identifier-field'
        },
        {
          key: 'classType',
          type: 'tdoe-select',
          props: {
            label: 'Class Type',
            options: [
              {
                label: 'Conditional',
                value: 'C'
              },
              {
                label: 'Pull-Out',
                value: 'P'
              },
              {
                label: 'Traditional',
                value: 'T'
              }
            ],
            multiple: true
          },
          className: 'class-type-field'
        },
        {
          key: 'teacherTln',
          type: 'tdoe-input',
          props: {
            label: 'Teacher Assigned'
          },
          className: 'teacher-assigned-field'
        },
        {
          key: 'classStartDate',
          type: 'tdoe-date',
          props: {
            label: 'Class Begin Date'
          },
          className: 'class-start-date-field'
        }
      ]
    }
  ];