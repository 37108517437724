import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionsService {

  private readonly http = inject(HttpClient);

  public getYears(): Observable<number[]> {
    return this.http.get<number[]>(`${environment.apiBaseUrl}sessions/years`);
  }

}
