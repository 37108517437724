<div class="d-flex">
    <div class="heading">
        <span class="period-name" [innerText]="'Period ' + reportingPeriod().periodNumber"></span>
        <div class="period-dates">
            <span class="period-date" [innerText]="reportingPeriod().beginDate | date: 'MMM d'"></span>
            <span class="period-conjunction">to</span>
            <span class="period-date" [innerText]="reportingPeriod().endDate | date: 'MMM d'"></span>
            <span class="period-year" [innerText]="reportingPeriod().endDate | date: 'yyyy'"></span>
        </div>
    </div>
    <full-calendar [options]="calendarOptions()" [deepChangeDetection]="true" #calendar></full-calendar>
</div>