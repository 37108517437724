import { CommonModule } from '@angular/common';
import { Component, Signal, signal } from '@angular/core';
import { ScopeContextFilterComponent } from 'app/components/shared/scope-context-filter/scope-context-filter.component';
import { ScopeContextFilterModel } from 'app/components/shared/scope-context-filter/scope-context-filter.model';
import { combineLatest} from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { StaffFilterComponent } from '../shared/staff-filter/staff-filter.component';
import { StaffTableComponent } from '../shared/staff-table/staff-table.component';
import { TableDataModel } from 'app/models/table-data-model';
import { StaffService } from 'app/services/staff/staff.service';
import { StaffMember, StaffSearchTerms } from 'app/dto';
import { UserModel } from 'app/services/user/user.model';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { UserService } from 'app/services/user/user.service';


@Component({
  selector: 'app-data-lookup-staff',
  standalone: true,
  imports: [
    CommonModule,
    ScopeContextFilterComponent,
    StaffFilterComponent,
    StaffTableComponent,
  ],
  templateUrl: './data-lookup-staff.component.html',
  styleUrls: ['./data-lookup-staff.component.scss']
})
export class DataLookupStaffComponent {

  private readonly _tablePagination = signal({ pageIndex: 0, pageSize: 50} as TableDataModel.Pagination);

  private readonly _tableSorting = signal({} as TableDataModel.Sorting);

  protected userProfile: Signal<UserModel.UserProfile | undefined>;

  protected isLoading = true;
  
  protected searchTerms = signal<StaffSearchTerms>({});

  protected readonly staffMembersPagedResponse = toSignal(combineLatest([
    toObservable(this.searchTerms),
    toObservable(this._tablePagination),
    toObservable(this._tableSorting)
  ]).pipe(
    debounceTime(300),
    map(([searchTerms, studentPagination, studentSorting]) => ({searchTerms, studentPagination, studentSorting})),
    switchMap(data => {
      return this._staffService.getStaffBySearchTerms(data.searchTerms, data.studentPagination, data.studentSorting)
        .pipe(map((staffMembersPagedResponse: TableDataModel.PagedResponse<StaffMember>) => staffMembersPagedResponse));
      })
  ));

  public constructor(
    private readonly _userProfileService: UserService,
    private readonly _staffService: StaffService) { 
    this.userProfile = toSignal<UserModel.UserProfile>(this._userProfileService.userProfile$.pipe(
      filter(userProfile => !!userProfile),
      tap(userProfile => { 
          this.searchTerms.update(searchTerms => ({
            ...searchTerms,
            schoolIds: userProfile?.scopeDetails.schoolIds,
            districtIds: userProfile?.scopeDetails.schoolIds
          }));
          this.isLoading = false;
        })
      ));
  }

  public ngAfterContentInit(): void {
    this.isLoading = !this.userProfile();
  }

  protected onStaffTableSortClicked(sorting: TableDataModel.Sorting): void {
    this._tableSorting.set(sorting);
  }

  protected onStaffTablePageChanged(pagination: TableDataModel.Pagination): void {
    this._tablePagination.set(pagination);
  }

  protected onContextFilterSearchClicked(selectedScopeContextFilterData: ScopeContextFilterModel.SelectedData): void {
    this.searchTerms.update(searchTerms =>
    { 
      delete searchTerms.schoolIDs;
      delete searchTerms.districtIDs;
      return {
        ...searchTerms,
        ...selectedScopeContextFilterData
      };
    });
  }

  protected onStaffFilterSearchClicked(staffSearchTerms: StaffSearchTerms): void {
    this.searchTerms.update(searchTerms => {
      delete searchTerms.nameLast;
      delete searchTerms.nameFirst;
      delete searchTerms.nameMiddle;
      delete searchTerms.teacherLicenseNumber;
      delete searchTerms.email;
      return {
        ...searchTerms,
        ...staffSearchTerms
      };
    });
  }

}