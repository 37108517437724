/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, computed, inject, input, model, output, signal, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ClassQuery, ClassSummary } from 'app/dto';
import { TableDataModel } from 'app/models/table-data-model';
import { catchError, debounceTime, distinctUntilChanged, Observable, tap } from 'rxjs';
import { AdditionalInfoComponent } from '../additional-info/additional-info.component';
import { AdditionalInfoModel } from 'app/services/additional-info/additional-info.model';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChipItem, TdoeButtonDirective, TdoeChipsComponent } from '@tdoe/design-system';
import { Dictionary } from 'app/models/dictionary';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';

import dayjs from 'dayjs';
import { MatMenuModule } from '@angular/material/menu';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { RouterModule } from '@angular/router';
import { StringUtilities } from 'app/utilities/string-utilities/string-utilities';
import { ClassTableConfig } from './class-table.config';

@Component({
  selector: 'app-class-table',
  standalone: true,
  imports: [
    CommonModule, 
    FormsModule, 
    MatMenuModule, 
    MatPaginatorModule, 
    MatTableModule, 
    MatSortModule,
    FormlyModule,
    FormlySelectModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    NgxSkeletonLoaderModule,
    TdoeButtonDirective,
    AdditionalInfoComponent, 
    TdoeChipsComponent,
    RouterModule
  ],
  templateUrl: './class-table.component.html',
  styleUrl: './class-table.component.scss',
  host: { class: 'sword-table' } 
})
export class ClassTableComponent implements AfterViewInit {
  public additionalInfoContextKey = 'ClassTableComponent';

  public totalRecords = input.required<number>();

  public classes = input.required<ClassSummary[] | undefined>();

  public query = model<ClassQuery>({ });

  public pageChanged = output<TableDataModel.Pagination>();

  public sortClicked = output<TableDataModel.Sorting>();

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  @ViewChild(MatSort)
  public sort!: MatSort;

  @ViewChild(AdditionalInfoComponent)
  protected additionalInfoComponent!: AdditionalInfoComponent;

  protected readonly dynamicColumns = signal<AdditionalInfoModel.Field[]>([]);

  protected isLoading = true;

  protected readonly formlyFormGroup = new FormGroup({});

  protected readonly additionalInfoFields: AdditionalInfoModel.Category[] = [];

  protected displayedColumns = computed(() => [
      ...this._staticColumns,
      ...this.dynamicColumns().map(field => field.key)
  ]);

  public readonly enableColumnFilters = false;
  public readonly enableAdditionalInfo = false;

  public formatClassName(classSummary: ClassSummary): string {
    return StringUtilities.FormatStringForUrl(classSummary.courseName ?? '');
  }

  public getChipText(columnName: string): string {
    return this._columnFiltersConfig[columnName]?.props?.label ?? columnName;
  }
  
  protected filterChipItems = computed(() => 
    Object.entries(this.query())
      .map(([key, value]) => ({ key, value }))
      .filter(kvp => !!kvp.value && !['schools', 'districts', 'pageScope', 'districtIds', 'schoolIds', 'year', 'years', 'pagination', 'sorting'].includes(kvp.key))
      .map(entry => ({
        text: `${this.getChipText(entry.key)}: '${this._columnFiltersConfig[entry.key]?.type === 'tdoe-date' ? dayjs(entry.value).format('M-D-YYYY') : entry.value }'`,
        persistent: false,
        key: entry.key,
        id: entry.key
      } as ChipItem)));

  public ngAfterViewInit(): void {
    this.isLoading = false;
    this.cdr.detectChanges();
  }
    
  protected onSortChanged(sort: Sort) : void {
    this.sortClicked.emit({
      sortColumn: sort.active,
      sortDirection: sort.direction
    } as TableDataModel.Sorting);
  }

  protected onResetFiltersClick(): void {
    this.query.set({});
  }

  protected onPageChanged(pageEvent: PageEvent): void {
    this.pageChanged.emit({ 
      pageSize: pageEvent.pageSize, 
      pageIndex: pageEvent.pageIndex } as TableDataModel.Pagination);
  }

  protected onAdditionalInfoSelectionChanged(categories: AdditionalInfoModel.Category[]): void {
    const selectedAdditionalInfoFields = categories.flatMap(additionalInfoCategory => additionalInfoCategory.fields)
      .filter(field => field.selected)
      .map(field => field);
  
    this.dynamicColumns.set(selectedAdditionalInfoFields);
  }

  protected getColumnFilterConfig(key: string): FormlyFieldConfig[] {
    const config = this.columnFilterConfigs()[key];
    if (!config) {
      // Do not delete: this will warn us if there are any missing or misspelled keys.
      console.warn('Could not find formly config for key:', key);
      return [];
    }
    return config;
  }

  protected filterChipItemRemoved(filterChipItem: ChipItem): void {
    this.removeSearchTerm(filterChipItem.id);
  }

  public queryName(classSummary: ClassSummary): string {
    return StringUtilities.FormatStringForUrl(`${classSummary.courseCode}`);
  }

  private readonly cdr = inject(ChangeDetectorRef);

  private readonly _columnFiltersConfig: { [key: string]: FormlyFieldConfig } = ClassTableConfig;

  private readonly columnFilterConfigs = computed(() => {
    const configs: { [key: string]: FormlyFieldConfig[] } = {};
    
    // Initialize all configs once
    Object.entries(this._columnFiltersConfig).forEach(([key, field]) => {
      const configField: FormlyFieldConfig  = { ...field }; 
      
      if (configField && !configField.hooks?.onInit) {
        configField.hooks = {
          ...configField.hooks,
          onInit: (field: FormlyFieldConfig): void => this.onFormlyFieldInit(field)
        };
      }
      
      configs[key] = [configField];
    });
    
    return configs;
  });

  private readonly _staticColumns = [
    'courseType',
    'courseCode',
    'localClassNumber',
    'sectionIdentifier',
    'classStartDate',
    'classEndDate',
    'classType',
    'teachingMethod',
    'testWindow'
  ];

  private onFormlyFieldInit(field: FormlyFieldConfig): void {
    if (!field.formControl || (field as any)._initialized) {
      return;
    }
    
    (field as any)._initialized = true;
    
    field.formControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(val => {
        if (val) {
          if (field.type === 'tdoe-date') {
            val = dayjs(val).format('YYYY-MM-DD');
          }
          this.addFilterItemToSearchTerms(field, val);
        } else {
          this.removeSearchTerm(field.key as string);
        }
      }),
      catchError(err => {
        console.error('Error in form field processing', err);
        return new Observable();
      })
    ).subscribe();
  }

  private removeSearchTerm(filterItemKey: string): void {
    this.query.update(searchTerms => {
      const updatedSearchTerms: Dictionary = { ... ObjectUtilities.removeFalsyStringProperties(searchTerms) };
      delete updatedSearchTerms[filterItemKey];
      return updatedSearchTerms  as ClassQuery;
    });
  }

  private addFilterItemToSearchTerms(field: FormlyFieldConfig, value: unknown): void {
    this.query.update(searchTerms => ({
      ...searchTerms,
      [field.key as string]: value
    }));
  }

}
