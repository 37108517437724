export * as StaffModel from './staff.model';

/** @deprecated use dto.StaffMember instead */
export interface Staff_Member {
  id: string;
  readonly name?: string;
  nameFirst: string;
  nameLast: string;
  nameMiddle: string;
  teacherLicenseNumber: string;
  licensureCheck: string;
  district: string;
  school: string;
  staffOrganizationEmail: string;
  schoolId: string;
  districtId: string;
}

export interface Staff_Search_Terms {
  lastName?: string;
  firstName?: string;
  middleName?: string;
  teacherLicenseNumber?: string;
  staffOrganizationEmail?: string;
  schoolIds?: string[];
  districtIds?: string[];
}