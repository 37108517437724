import { Directive, inject, Input, signal, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagModel } from 'app/services/feature-flag/feature-flag.model';
import { FeatureFlagService } from 'app/services/feature-flag/feature-flag.service';

@Directive({
  selector: '[featureFlag]',
  standalone: true
})
export class FeatureFlagDirective {

  private _templateRef = inject(TemplateRef);
  private _viewContainer = inject(ViewContainerRef);
  private _featureFlagService = inject(FeatureFlagService);
  private _hasView = signal(false);

  @Input() public set featureFlag(feature: FeatureFlagModel.FeatureFlagKeys) {
    if (this._featureFlagService.getFeature(feature) && !this._hasView()) {
      this._viewContainer.createEmbeddedView(this._templateRef);
      this._hasView.set(true);
    } else {
      this._viewContainer.clear();
      this._hasView.set(false);
    }
  }

  @Input() public set featureFlagElse(elseTemplateRef: TemplateRef<unknown>) {
    if (!this._hasView()) {
      this._viewContainer.createEmbeddedView(elseTemplateRef);
    }
  }

}
