import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { District, School, SchoolQuery } from 'app/dto';
import { HttpParamsUtilities } from 'app/utilities/http-params-utilities/http-params-utilities';
import { PagedResponse, Pagination, Sorting } from 'app/models';

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  public constructor(private _httpClient: HttpClient) { }

  public static SchoolUrl = environment.apiBaseUrl + 'School/School';
  public static SchoolsUrl = environment.apiBaseUrl + 'School/Schools';
  public static DistrictUrl = environment.apiBaseUrl + 'School/District';
  public static DistrictsUrl = environment.apiBaseUrl + 'School/Districts';

  public getSchools(districtIds: string[]): Observable<School[]> {
    if ((districtIds?.length ?? 0) === 0) throw new Error('districtId is required.');

    const httpParams = HttpParamsUtilities.toHttpParams({ districtId: districtIds });

    return this._httpClient.get<School[]>(SchoolService.SchoolsUrl, { params: httpParams });
  }

  public getDistricts(districtIds?: string[]): Observable<District[]> {
    const httpParams = HttpParamsUtilities.toHttpParams({ districtId: districtIds ?? [] });

    return this._httpClient.get<District[]>(SchoolService.DistrictsUrl, { params: httpParams });
  }

  public getSchool(schoolId: string): Observable<School> {
    return this._httpClient.get<School>(SchoolService.SchoolUrl, { params: { schoolId } });
  }

  public getDistrict(districtId: string): Observable<District> {
    return this._httpClient.get<District>(SchoolService.DistrictUrl, { params: { districtId } });
  }

  public searchSchools(schoolQuery: SchoolQuery, pagination: Pagination, sorting: Sorting): Observable<PagedResponse<School>> {
    const httpParams = HttpParamsUtilities.toHttpParams(schoolQuery, pagination, sorting);
    return this._httpClient.get<PagedResponse<School>>(`${environment.apiBaseUrl}School/search`, { params: httpParams });
  }

}
