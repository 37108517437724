import { APP_INITIALIZER, inject, Provider } from '@angular/core';
import { Observable } from 'rxjs';
import { FeatureFlagModel } from './feature-flag.model';
import { FeatureFlagService } from './feature-flag.service';

function initializeFeatureFlag(): () => Observable<FeatureFlagModel.FeatureFlags> {
    const featureFlagService = inject(FeatureFlagService);
    return () => featureFlagService.getFeatureFlags();
}

export const provideFeatureFlag = (): Provider => ({
    provide: APP_INITIALIZER,
    useFactory: initializeFeatureFlag,
    deps: [],
    multi: true,
});
