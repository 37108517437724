import { Component, computed, input, output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatSelectModule } from '@ngx-formly/material/select';
import { TdoeButtonDirective } from '@tdoe/design-system';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import * as formlyConfigs from './staff-filter.formly-config';
import { StaffSearchTerms } from 'app/dto';

@Component({
  selector: 'app-staff-filter',
  templateUrl: './staff-filter.component.html',
  styleUrl: './staff-filter.component.scss',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatSelectModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule,
    TdoeButtonDirective,
    MatExpansionModule
  ],
  host: {
    class: 'quick-search'
  }
})
export class StaffFilterComponent {

  public searchClicked = output<StaffSearchTerms>();
  
  public staffSearchTerms = input<StaffSearchTerms>({});

  public combinedSearchTerms = computed(() =>({...this.staffSearchTerms()}));

  protected formlyFormGroup = new FormGroup({});

  protected formlyFieldConfigs = formlyConfigs.formlyFieldConfigs;

  protected expanded = false;

  protected onSearchClick(): void {
    this.searchClicked.emit(ObjectUtilities.removeFalsyStringProperties(this.formlyFormGroup.value));
  }
}