import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, signal, computed } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { toSignal } from '@angular/core/rxjs-interop';
import { AdditionalInfoComponent } from '../shared/additional-info/additional-info.component';
import { AdditionalInfoService } from 'app/services/additional-info/additional-info.service';
import { StaffService } from 'app/services/staff/staff.service';
import { StaffMember } from 'app/dto/staffMember';
import { ExtendedCategory, staffViewAdditionalInfoData, staffViewFields } from './staff-view-config';
import { MatTableModule } from '@angular/material/table';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import { MatMenuModule } from '@angular/material/menu';
import { TdoeButtonDirective } from '@tdoe/design-system';

@Component({
  selector: 'app-staff-view',
  standalone: true,
  imports: [
    AdditionalInfoComponent,
    MatExpansionModule,
    MatIconModule,
    CommonModule,
    MatTabsModule,
    MatTableModule,
    RouterModule,
    MatMenuModule,
    TdoeButtonDirective
  ],
  templateUrl: './staff-view.component.html',
  styleUrls: ['./staff-view.component.scss']
})
export class StaffViewComponent implements OnInit {
  protected readonly additionalInfoContextKey = 'staff-data-lookup';

  public readonly fakeStaffCurrentAssignments = [
    {
      assignmentCode: 'ABC123',
      assignmentBeginDate: '2025-01-01',
      assignmentEndDate: '2025-03-15'
    },
    {
      assignmentCode: 'DEF456',
      assignmentBeginDate: '2025-04-01',
      assignmentEndDate: '2025-06-30'
    }
  ];

  public readonly additionalInfoFields: ExtendedCategory[] = staffViewAdditionalInfoData;
  public readonly staffFields = staffViewFields;
  private readonly _staffMemberSig = toSignal(
    this._staffService.getStaffMember(this._route.snapshot.paramMap.get('id') ?? '0'),
    { initialValue: null }
  );

  private readonly _categoriesSig = signal<ExtendedCategory[]>([]);
  private readonly _displayCategories = computed<ExtendedCategory[]>(() => {
    const staffMember = this._staffMemberSig();
    const categories = this._categoriesSig();
    if (!staffMember) return [];
    return categories
      .filter(category => category.fields.some(field => field.selected))
     .map(category => ({
        ...category,
        fields: category.fields
          .filter(field => field.selected)
          .map(field => ({
            ...field,
            value: ObjectUtilities.getNestedProperty(staffMember, field.key)
          }))
      }));
  });

  protected readonly viewModel = computed(() => ({
    staffMember: this._staffMemberSig(),
    staffCategories: this._displayCategories()
  }));

  public constructor(
    private readonly _route: ActivatedRoute,
    private readonly _titleService: Title,
    private readonly _staffService: StaffService,
    private readonly _additionalInfoService: AdditionalInfoService,
    private readonly _location: Location
  ) {}

  public ngOnInit(): void {
    const staffName = this._route.snapshot.paramMap.get('name') || 'Staff View';
    this._titleService.setTitle(`${this._titleService.getTitle()} - ${staffName}`);
    const savedCategories = this._additionalInfoService.getFieldStateNew(this.additionalInfoContextKey);
    if (savedCategories?.length) {
      this._categoriesSig.set(savedCategories as ExtendedCategory[]);
    } else {
      this._categoriesSig.set(this.additionalInfoFields);
    }
  }

  public getColumnKeys(category: ExtendedCategory): string[] {
    return category.fields.map(field => field.key);
  }

  // update on removal of fake data
  public getCategoryData(category: ExtendedCategory, staffMember: StaffMember | null): unknown[] {
    if (!staffMember) return [];
    if (category.name === 'Staff Current Assignments') {
      return this.fakeStaffCurrentAssignments;
    }
    return staffMember.additionalInfo?.staffAssignments ?? [];
  }

  public getStaffMemberValue(obj: unknown, key: string): unknown {
    return ObjectUtilities.getNestedProperty(obj, key);
  }

  protected onAdditionalInfoSelectionChanged(categories: ExtendedCategory[]): void {
    this._categoriesSig.set(categories);
  }

  protected onSaveClicked(): void {
    this._additionalInfoService.saveFieldStateToLocalStorage(
      this._categoriesSig(),
      this.additionalInfoContextKey
    );
  }

  protected onResetClicked(): void {
    const resetState = this._additionalInfoService.getSavedFieldStateNew(this.additionalInfoContextKey)
      ?? this.additionalInfoFields;
    this._categoriesSig.set(resetState as ExtendedCategory[]);
  }

}