import { AdditionalInfoModel } from 'app/services/additional-info/additional-info.service';

export interface ExtendedCategory extends AdditionalInfoModel.Category {
  isArray?: boolean;
}

export const staffViewAdditionalInfoData: ExtendedCategory[] = [
  {
    name: 'Staff Current Assignments',
    expanded: false,
    isArray: true,
    fields: [
      { name: 'Assignment Code', key: 'assignmentCode', selected: false },
      { name: 'Current Assignment Begin Date', key: 'assignmentBeginDate', selected: false },
      { name: 'Current Assignment End Date', key: 'assignmentEndDate', selected: false }
    ]
  },

  {
    name: 'Staff Class Assignments',
    expanded: false,
    isArray: true,
    fields: [
      { name: 'Staff Class Assignment Begin Date', key: 'assignmentStart', selected: false },
      { name: 'Staff Class Assignment End Date', key: 'assignmentEnd', selected: false },
      { name: 'Class Begin Date', key: 'classStart', selected: false },
      { name: 'Class End Date', key: 'classEnd', selected: false },
      { name: 'Course Code', key: 'courseCode', selected: false },
      { name: 'Local Class Number', key: 'localClassNumber', selected: false },
      { name: 'Teacher of Record', key: 'teacherOfRecord', selected: false },
      { name: 'Period & Duration', key: 'periodDuration', selected: false },
    ]
  },
  
  {
    name: 'Staff Licensure & Endorsement Details',
    expanded: false,
    fields: [
      { name: 'License Number', key: 'teacherLicenseNumber', selected: false },
      { name: 'Experience', key: 'experienceInYears', selected: false },
      { name: 'Education Level', key: 'educationLevel', selected: false },
      { name: 'Conferral Date', key: 'conferralDate', selected: false },
      { name: 'Date Added to File', key: 'addedToFile', selected: false },
      { name: 'Date Issued', key: 'additionalInfo.staffMemberLicensureDetails.dateIssued', selected: false },
      { name: 'Type', key: 'additionalInfo.staffMemberLicensureDetails.type', selected: false },
      { name: 'Expiration Date', key: 'additionalInfo.staffMemberLicensureDetails.expirationDate', selected: false },
      { name: 'Renewed Date', key: 'additionalInfo.staffMemberLicensureDetails.renewedDate', selected: false },
      { name: 'Number of Renewals', key: 'additionalInfo.staffMemberLicensureDetails.numberOfRenewals', selected: false }
    ]
  },
];

// static fields
export const staffViewFields = [
  { key: 'name', label: 'Last, First, Middle Name', isNameField: true },
  { key: 'email', label: 'Staff Organization Email' },
  { key: 'district', label: 'District' },
  { key: 'school', label: 'School' },
  { key: 'gender', label: 'Gender' },
  { key: 'teacherLicenseNumber', label: 'License Number' },
  { key: 'licensureCheck', label: 'Licensure Check' },
  { key: 'employmentStatus', label: 'Status' }
];