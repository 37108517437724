<div class="container" *ngIf="viewModel().staffMember as staffMember">
  <div class="container">
    <div class="header">
      <div class="back-link">
        <a [routerLink]="['/data-lookup/staff']">
          <mat-icon>arrow_back</mat-icon> Back to Staff List
        </a>
      </div>
      <div class="buttons">
        <app-additional-info
          [additionalInfoFields]="additionalInfoFields"
          [contextKey]="additionalInfoContextKey"
          (fieldSelected)="onAdditionalInfoSelectionChanged($event)" />
          <button tdoe-button size="large" [matMenuTriggerFor]="menu">Export</button>
          <mat-menu #menu="matMenu" xPosition="before">
              <button mat-menu-item>PDF</button>
          </mat-menu>
      </div>
    </div>

    <div mat-accordion class="mat-accordion">
      <mat-expansion-panel expanded hideToggle disabled>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Staff Personal Info
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="data-list">
          <ng-container *ngFor="let field of staffFields">
            <div *ngIf="field.isNameField; else defaultField" class="field name-field">
              <h3 class="value">
                {{ staffMember.nameLast }}, {{ staffMember.nameFirst }}, {{ staffMember.nameMiddle }}
              </h3>
              <span class="name">{{ field.label }}</span>
            </div>
            <ng-template #defaultField>
              <div class="field">
                <span class="name">{{ field.label }}</span>
                <span class="value">{{ getStaffMemberValue(staffMember, field.key) }}</span>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </mat-expansion-panel>

      <ng-container *ngFor="let category of viewModel().staffCategories">
        
        <!-- Non-array categories -->
        <mat-expansion-panel *ngIf="!category.isArray" [expanded]="category.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ category.name }}</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="data-list">
            <div *ngFor="let field of category.fields" class="field">
              <span class="name">{{ field.name }}</span>
              <span class="value">{{ getStaffMemberValue(staffMember, field.key) }}</span>
            </div>
          </div>
        </mat-expansion-panel>

        <!-- Array-based categories -->
        <mat-expansion-panel *ngIf="category.isArray" [expanded]="category.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ category.name }}</mat-panel-title>
          </mat-expansion-panel-header>

          <!-- Sub-section for dynamic table -->
          <div class="sub-section staff-assignments-table">
            <mat-table [dataSource]="getCategoryData(category, staffMember)">

              <!-- Dynamic columns -->
              <ng-container *ngFor="let field of category.fields" [matColumnDef]="field.key">
                <mat-header-cell *matHeaderCellDef>
                  {{ field.name }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  {{ getStaffMemberValue(row, field.key) }}
                </mat-cell>
              </ng-container>

              <!-- Header and data rows -->
              <mat-header-row *matHeaderRowDef="getColumnKeys(category)"></mat-header-row>
              <mat-row *matRowDef="let row; columns: getColumnKeys(category)"></mat-row>
            </mat-table>
          </div>
        </mat-expansion-panel>

      </ng-container>
    </div>
  </div>
</div>