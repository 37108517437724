import { Injectable } from '@angular/core';
import { NavItem } from '@tdoe/design-system';
import { Observable, of, map, catchError, combineLatest, switchMap } from 'rxjs';
import { ErrorLogModel, ErrorLogService } from '../error-log/error-log.service';
import { TypeGuards } from 'app/type-guards';
import { PageScope } from 'app/enums/page-scope';
import { UserService } from '../user/user.service';
import { ScopeDetails } from '../user/user.model';

interface NavItemFilterable extends NavItem {
  right?: string;
}

@Injectable({
  providedIn: 'root'
})
export class HeaderNavItemsService {

  private _navItemsFilterable$: Observable<NavItemFilterable[]> = of([
    {
      id: 'dashboard',
      text: 'Dashboard',
    },
    {
      id: 'data-lookup',
      text: 'Data Lookup',
      children: [
        { id: 'data-lookup/school', text: 'School' },
        { id: 'data-lookup/class', text: 'Class' },
        { id: 'data-lookup/staff', text: 'Staff' },
        { id: 'data-lookup/student', text: 'Student' },
        { id: 'data-lookup/statewide', text: 'Statewide' }
      ]
    },
    {
      id: 'error-log',
      text: 'Error Log',
      children: [
        { id: 'error-log/school', text: 'School' },
        { id: 'error-log/class', text: 'Class' },
        { id: 'error-log/staff', text: 'Staff' },
        { id: 'error-log/student', text: 'Student' },
        { id: 'error-log/total', text: 'Total' }
      ]
    },
    {
      id: 'reports',
      text: 'Reports'
    },
  ] as NavItemFilterable[]);

  private _navItems$ = combineLatest([
    this._navItemsFilterable$,
    this._userService.userProfile$
  ]).pipe(
    map(([navItems, userProfile]) => ({ navItems, userProfile })),
    switchMap(data => {
      const pageScope = data.userProfile?.pageScope;
      const scopeDetails = data.userProfile?.scopeDetails;

      if(pageScope && scopeDetails){
        return this.getErrorCounts(pageScope, scopeDetails).pipe(
          catchError(error => {
            console.error(error);
            return of([]);
          }),
          map(errorCounts => ErrorLogService.convertToErrorCounts(errorCounts)),
          map(errorCounts => {
            const navItems = this.setAlertBadge(data.navItems, errorCounts.total);
            return this.filterNavItems(navItems, []);
          })
        );
      }
      else {
        return of(this.filterNavItems(data.navItems, []));
      }
    })
  );

  public constructor(
    private _userService: UserService,
    private _errorLogService: ErrorLogService) {
  }

  private getErrorCounts(pageScope: PageScope, scopeDetails: ScopeDetails) : Observable<ErrorLogModel.CategoryErrorCount[]>{
    console.log('HeaderNavItemsService -> getErrorCounts', {
      pageScope,
      scopeDetails
    });

    switch (pageScope) {
      case PageScope.State:{
        return this._errorLogService.getStateCounts();
      }
      case PageScope.District: {
        return this._errorLogService.getDistrictCounts(scopeDetails.districtIds);
      }
      case PageScope.School: {
        return this._errorLogService.getSchoolCounts(scopeDetails.schoolIds);
      }
      default: {
        throw Error('Unknown page scope.');
      }
    }
  }

  private setAlertBadge(navItems: NavItemFilterable[], totalErrors: number): NavItemFilterable[] {
    console.log('HeaderNavItemsService -> setAlertBadge', {
      navItems,
      totalErrors
    });
    const errorLogNavItem = navItems.find(item => item.id === 'error-log');
    if (TypeGuards.isNavItem(errorLogNavItem)) {
      errorLogNavItem.alert = (totalErrors > 0);
    }
    return navItems;
  }

  public getNavItems(): Observable<NavItem[]> {
    return this._navItems$;
  }

  private filterNavItems(navItems: NavItemFilterable[], userRights: string[]): NavItem[] {
    console.log('HeaderNavItemsService -> filterNavItems', {
      navItems,
      userRights
    });
    return navItems.filter(navItem => {
      if (navItem.right && !userRights.includes(navItem.right)) {
        return false;
      }
      if (navItem.children) {
        navItem.children = this.filterNavItems(navItem.children, userRights);
        return navItem.children.length > 0;
      }
      return true;
    });
  }
}