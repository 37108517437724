
<div class="container" *ngIf="viewModel$ | async as viewData">
    <div class="container">
        <div class="header">
            <div class="back-link">
                <a [routerLink]="['/data-lookup/student']"><mat-icon>arrow_back</mat-icon> Back to Student List</a>
            </div>
            <div class="buttons">
                <app-additional-info
                    [additionalInfoFields]="additionalInfoFields"
                    [contextKey]="additionalInfoContextKey"
                    (fieldSelected)="onFieldSelected($event)" />
                <button tdoe-button size="large" [matMenuTriggerFor]="menu">Export</button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item>PDF</button>
                </mat-menu>
            </div>
        </div>
        <div class="mat-accordion">
            <mat-expansion-panel *ngIf="viewData.student as student" expanded hideToggle disabled>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Student Personal Info
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="data-list">
                    <div class="field name-field">
                        <span class="name">Last, First, Middle Name</span>
                        <span class="value">{{student.nameLast}}, {{student.nameFirst}} {{student.nameMiddle}}</span>
                    </div>
                    <div class="field">
                        <span class="name">SSID</span>
                        <span class="value">{{student.ssid}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Grade</span>
                        <span class="value">{{student.grade}}</span>
                    </div>
                    <div class="field">
                        <span class="name">District</span>
                        <span class="value">{{student.district}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Service District</span>
                        <span class="value">{{student.serviceDistrict}}</span>
                    </div>
                    <div class="field">
                        <span class="name">School</span>
                        <span class="value">{{student.school}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Service School</span>
                        <span class="value">{{student.serviceSchool}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Student Standard Day (Minutes)</span>
                        <span class="value">{{student.standardDayMinutes}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Service Type</span>
                        <span class="value">{{student.serviceType}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Withdrawal End Date</span>
                        <span class="value">{{student.withdrawalEndDate}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Withdrawal Code</span>
                        <span class="value">{{student.withdrawalCode}}</span>
                    </div>
                    <div class="field">
                        <span class="name">Gender</span>
                        <span class="value">{{student.gender}}</span>
                    </div>
                </div>
            </mat-expansion-panel>
            <ng-container *ngFor="let category of viewData.staticSections">
                <ng-template [ngTemplateOutlet]="categoryPanel" [ngTemplateOutletContext]="{category: category}"></ng-template>
            </ng-container>
        </div>
        <div class="mat-accordion">
            <mat-expansion-panel *ngIf="viewData.student as student">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Student Enrollment Details
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="sub-section">
                    <h3>End of service</h3>
                    <div class="data-list">
                        <div class="field">
                            <span class="name">EOS Date</span>
                            <span class="value">{{student.enrollment?.endOfService?.endOfServiceDate}}</span>
                        </div>
                        <div class="field">
                            <span class="name">EOS Action</span>
                            <span class="value">{{student.enrollment?.endOfService?.eosAction}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Comp Doc Date</span>
                            <span class="value">{{student.enrollment?.endOfService?.compDocDate | date}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Comp Doc Type</span>
                            <span class="value">{{student.enrollment?.endOfService?.compDocType}}</span>
                        </div>
                        <div class="field">
                            <span class="name">Comp Doc Period</span>
                            <span class="value">{{student.enrollment?.endOfService?.compDocPeriod}}</span>
                        </div>
                    </div>
                </div>
                <div class="sub-section">
                    <h3>Classification</h3>
                    <app-enrollment-classification [classifications]="student.enrollment?.classifications!"></app-enrollment-classification>
                </div>
                <div class="sub-section" *ngIf="student.enrollment">
                    <h3>History</h3>
                    <app-enrollment-history [studentEnrollmentHistorySet]="student.enrollment.enrollmentHistory!"></app-enrollment-history>
                </div>
            </mat-expansion-panel>
        </div>
        <div class="mat-accordion" *ngIf="viewData.clubMembershipCategory as clubMembershipCat">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Club Membership
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-table [dataSource]="viewData.student.enrollment?.clubMembership ?? []">
                        
                    @for (column of clubMembershipCat.fields;track column) {
                        <ng-container [matColumnDef]="column.key">
                            <mat-header-cell *matHeaderCellDef>{{ column.name }}</mat-header-cell>
                            <mat-cell *matCellDef="let membership">{{resolveNestedProperty(membership, column)}}</mat-cell>
                        </ng-container>
                    }

                    <mat-header-row *matHeaderRowDef="viewData.clubMembershipColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: viewData.clubMembershipColumns"></mat-row>

                </mat-table>
            </mat-expansion-panel>
        </div>
    </div>
</div>

<ng-template #categoryPanel let-category="category">
    <mat-expansion-panel [(expanded)]="category.expanded" >
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{category.name}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="data-list">
            <ng-container *ngFor="let field of category.fields">
                <div class="field">
                    <span class="name">{{field.name}}</span>
                    <span class="value">{{field.value }}</span>
                </div>
            </ng-container>
        </div>
    </mat-expansion-panel>
</ng-template>