@if (isLoading || !students()) {
    <div class="skeleton-buttons">
        <ngx-skeleton-loader [theme]="{ 'width':'30%', 'min-width': '9rem','height': '2.5rem' }" count="3" appearance="line"/>
    </div>
    <ngx-skeleton-loader [theme]="{ 'height': '2rem' }" count="1" appearance="line"/>
    <ngx-skeleton-loader count="25" appearance="line"/>
}
@else {
    <div class="header">
        @if (filterChipItems() && filterChipItems().length > 0) {
            <tdoe-chips [chips]="filterChipItems()" (onChipRemove)="filterChipItemRemoved($event)"/>
        } @else {
            <h3></h3>
        }
        <div class="controls">
            <div class="buttons">
                <app-additional-info
                    [additionalInfoFields]="additionalInfoFields"
                    [contextKey]="additionalInfoContextKey"
                    (fieldSelected)="onAdditionalInfoSelectionChanged($event)" />
                <button tdoe-button size="large" (click)="onResetFiltersClick()" >Reset Filters</button>
                <button tdoe-button size="large" [matMenuTriggerFor]="menu">Export</button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item>CSV</button>
                    <button mat-menu-item>PDF</button>
                    <button mat-menu-item>Excel</button>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="table-title">
        <span>Student Information</span>
    </div>
    <div class="scrollable-container">
        <mat-table matSort [dataSource]="students()!" (matSortChange)="onSortChanged($event)">
            <ng-container matColumnDef="nameLast">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <div>Last</div>
                    <form *featureFlag="'studentDLColumnFilters'" class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('nameLast')" [model]="searchTerms()"></formly-form>
                    </form>
                </mat-header-cell>
                <mat-cell *matCellDef="let student"> {{student.nameLast}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="nameFirst">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <div>First</div>
                    <form *featureFlag="'studentDLColumnFilters'" class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('nameFirst')" [model]="searchTerms()"></formly-form>
                    </form>
                </mat-header-cell>
                <mat-cell *matCellDef="let student"> {{student.nameFirst}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="nameMiddle">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <div>Middle</div>
                    <form *featureFlag="'studentDLColumnFilters'" class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('nameMiddle')" [model]="searchTerms()"></formly-form>
                    </form>
                </mat-header-cell>
                <mat-cell *matCellDef="let student"> {{student.nameMiddle}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="ssid">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <div>SSID</div>
                    <form *featureFlag="'studentDLColumnFilters'" class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('ssid')" [model]="searchTerms()"></formly-form>
                    </form>
                </mat-header-cell>
                <mat-cell *matCellDef="let student" > {{student.ssid | ssidFormat}} </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="dateOfBirth">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <div>Date of Birth</div>
                    <form *featureFlag="'studentDLColumnFilters'" class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('dateOfBirth')" [model]="searchTerms()"></formly-form>
                    </form>
                </mat-header-cell>
                <mat-cell *matCellDef="let student"> {{student.dateOfBirth}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="grade">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <div>Grade</div>
                    <form *featureFlag="'studentDLColumnFilters'" class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('grades')" [model]="searchTerms()"></formly-form>
                    </form>
                </mat-header-cell>
                <mat-cell *matCellDef="let student"> {{student.grade}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="district" >
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <div>District</div>
                    <form *featureFlag="'studentDLColumnFilters'" class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('district')" [model]="searchTerms()"></formly-form>
                    </form>
                </mat-header-cell>
                <mat-cell *matCellDef="let student"> {{student.district}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="school" >
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <div>School</div>
                    <form *featureFlag="'studentDLColumnFilters'" class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('school')" [model]="searchTerms()"></formly-form>
                    </form>
                </mat-header-cell>
                <mat-cell *matCellDef="let student"> {{student.school}} </mat-cell>
            </ng-container>

            @if (dynamicColumns().length > 0) {
                @for (col of dynamicColumns(); track col) {
                    <ng-container [matColumnDef]="col.key">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div>{{col.name}}</div>
                            <form *featureFlag="'studentDLColumnFilters'" class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                                <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig(col.key)" [model]="searchTerms()"></formly-form>
                            </form>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let student"> {{resolveNestedProperty(student, col)}} </mat-cell>
                    </ng-container>
                }
            }

            <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
            <mat-row *matRowDef="let student; columns: displayedColumns()" [attr.student-id]="student.id" (click)="onRowClicked(student)"></mat-row>
                    
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching applied filters</td>
            </tr>
        </mat-table>
    </div>
    <mat-paginator
        #paginator
        [pageIndex]="pageIndex()"
        [length]="totalRecords()"
        pageSize="50"
        [pageSizeOptions]="[25, 50, 100, 500, 1000]"
        showFirstLastButtons
        (page)="onPageChanged($event)">
    </mat-paginator>
}
    