import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ClassDetail, ClassQuery, ClassSummary } from 'app/dto';
import { environment } from 'environments/environment';
import { PagedResponse, Pagination, Sorting } from 'app/models';
import { HttpParamsUtilities } from 'app/utilities/http-params-utilities/http-params-utilities';

@Injectable({
  providedIn: 'root'
})
export class ClassService {
  private http = inject(HttpClient);

  public getClassesBySearchTerms(classQuery: ClassQuery, pagination: Pagination, sorting: Sorting): Observable<PagedResponse<ClassSummary>>  {
    const httpParams = HttpParamsUtilities.toHttpParams(classQuery, pagination, sorting);
    return this.http.get<PagedResponse<ClassSummary>>(`${environment.apiBaseUrl}classes/search`, { params: httpParams });
  }

  public getClass(id: string): Observable<ClassDetail> {
    return this.http.get<ClassDetail>(`${environment.apiBaseUrl}classes/${id}`);
  }
}
