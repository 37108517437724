import { AfterContentInit, Component, inject, Signal, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScopeContextFilterComponent } from 'app/components/shared/scope-context-filter/scope-context-filter.component';
import { ClassTableComponent } from '../shared/class-table/class-table.component';
import { ClassFilterComponent } from '../shared/class-filter/class-filter.component';
import { TableDataModel } from 'app/models';
import { UserModel } from 'app/services/user/user.model';
import { ClassQuery, ClassSummary } from 'app/dto';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, debounceTime, filter, map, switchMap, tap } from 'rxjs';
import { ClassService } from 'app/services/class/class.service';
import { UserService } from 'app/services/user/user.service';
import { ScopeContextFilterModel } from 'app/components/shared/scope-context-filter/scope-context-filter.model';

@Component({
  selector: 'app-data-lookup-class',
  templateUrl: './data-lookup-class.component.html',
  styleUrl: './data-lookup-class.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    ClassTableComponent,
    ClassFilterComponent,
    ScopeContextFilterComponent
  ]
})
export class DataLookupClassComponent implements AfterContentInit {

  private readonly tablePagination = signal({ pageIndex: 0, pageSize: 50} as TableDataModel.Pagination);
  private readonly tableSort = signal({} as TableDataModel.Sorting);

  private readonly classService = inject(ClassService);
  private readonly userService = inject(UserService);

  protected userProfile: Signal<UserModel.UserProfile | undefined>;

  protected isLoading = true;

  protected query = signal<ClassQuery>({});
    
  protected readonly pagedResponse = toSignal(combineLatest([
    toObservable(this.query),
    toObservable(this.tablePagination),
    toObservable(this.tableSort)
  ]).pipe(
    debounceTime(300),
    map(([query, pagination, sorting]) => ({query, pagination, sorting})),
    switchMap(data => {
      return this.classService.getClassesBySearchTerms(data.query, data.pagination, data.sorting
      ).pipe(
        map((pagedResponse: TableDataModel.PagedResponse<ClassSummary>) => pagedResponse));
      })
  ));

  public constructor() {
    this.userProfile = toSignal<UserModel.UserProfile>(this.userService.userProfile$.pipe(
      filter(userProfile => !!userProfile),
      tap(userProfile => { 
          this.query.update(query => ({
            ...query,
            schools: userProfile?.scopeDetails.schoolIds,
            districts: userProfile?.scopeDetails.schoolIds
          }));
          this.isLoading = false;
        })
      ));
  }
  
  public ngAfterContentInit(): void {
    this.isLoading = !this.userProfile();
  }

  protected onTableSortClicked(sorting: TableDataModel.Sorting): void {
    this.tableSort.set(sorting);
  }

  protected onTablePageChanged(pagination: TableDataModel.Pagination): void {
    this.tablePagination.set(pagination);
  }

  protected onContextFilterSearchClicked(selectedScopeContextFilterData: ScopeContextFilterModel.SelectedData): void {
    this.query.update(query => ({
      ...query,
      ...selectedScopeContextFilterData
    }));
  }

  protected onQuickSearch(classQuery: ClassQuery): void {
    this.query.update(query => ({
      ...query,
      ...classQuery
    }));
  }
}
