/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ScopeContextFilterModel } from '../scope-context-filter.model';
import { SchoolService } from 'app/services/school/school.service';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoggingService, TdoeButtonComponent, TdoeSelectItem } from '@tdoe/design-system';
import { BehaviorSubject, firstValueFrom, tap } from 'rxjs';
import { PageScope } from 'app/enums/page-scope';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatIconModule } from '@angular/material/icon';
import { SessionsService } from 'app/services/sessions/sessions.service';

@Component({
  selector: 'app-school-scope-context-filter',
  templateUrl: './school-scope-context-filter.component.html',
  styleUrls: ['./school-scope-context-filter.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    MatIconModule,
    TdoeButtonComponent
  ],
  host: { class: 'scope-context-filter' } 
})
export class SchoolScopeContextFilterComponent implements OnChanges {

  @Input()
  public years: number[] = [new Date().getFullYear()];

  @Input()
  public districtIds: string[] | undefined;
  
  @Input()
  public schoolIds: string[] | undefined;

  @Output() public districtIdsChange = new EventEmitter<string[] | undefined>();

  @Output() public schoolIdsChange = new EventEmitter<string[] | string[] | undefined>();
  
  @Output()
  public searchClick = new EventEmitter<ScopeContextFilterModel.SelectedData> ();

  private districtOptions$ = new BehaviorSubject<TdoeSelectItem[]>([]);
  private schoolOptions$ = new BehaviorSubject<TdoeSelectItem[]>([]);
  private yearOptions$ = new BehaviorSubject<TdoeSelectItem[]>([]);

  protected searchTerms?: ScopeContextFilterModel.SelectedData;

  public constructor(
    private readonly _schoolService: SchoolService,
    private readonly sessionsService: SessionsService,
    private readonly logger: LoggingService
  ) {
    this.loadYears();
    this.loadDistricts();
  }

  public formlyForm = new FormGroup({});
  public formlyModel: any = {};
  public formlyFields: FormlyFieldConfig[] = [
    {
      key: 'year',
      type: 'tdoe-select',
      className: 'tdoe-grow-0',
      props: {
        label: 'Select Year',
        options: this.yearOptions$.asObservable(),
        appearance: 'outline',
        multiple: false
      }
    },
    {
      key: 'districts',
      type: 'tdoe-select',
      className: 'tdoe-grow-1',
      props: {
        label: 'Select Districts',
        options: this.districtOptions$.asObservable(),
        appearance: 'outline',
        multiple: true
      },
      hooks: {
        onChanges: (): void => {
          delete this.formlyModel.schools;
          if ((this.formlyModel.districts?.length ?? 0) > 0) {
            this.loadSchools();
          } else {
            delete this.formlyModel.schools;
          }
        }
      },
      expressions: {
        'props.disabled': (): boolean => {
          return this.districtOptions$.getValue().length < 2;
        }
      }
    },
    {
      key: 'schools',
      type: 'tdoe-select',
      className: 'tdoe-grow-1',
      props: {
        label: 'Select Schools',
        options: this.schoolOptions$.asObservable(),
        appearance: 'outline',
        multiple: true
      },
      hooks: {
        onInit: (field: FormlyFieldConfig): void => {
          const districtControl = (field as any).parent.get('districts').formControl;
          districtControl.valueChanges.pipe(
            tap(() => {
              if ((districtControl.value?.length ?? 0) > 0) {
                this.loadSchools();
              } else {
                this.schoolOptions$.next([]);
              }
            })
          ).subscribe();
        }
      },
      expressions: {
        'props.disabled': (): boolean => {
          return this.schoolOptions$.getValue().length < 2;
        }
      }
    }
  ];

  private async loadDistricts(): Promise<void> {
    const districts = await firstValueFrom(this._schoolService.getDistricts(this.districtIds));
    const districtOptions = districts.map(district => ({
      text: `${district.districtNumber} - ${district.name}`,
      value: district.districtId,
      selected: this.formlyModel.districts?.includes(district.districtId) || districts.length === 1
    } as TdoeSelectItem));
    this.logger.debug('loadDistricts', { data: { districtOptions } });
    this.districtOptions$.next(districtOptions);
  }

  private async loadSchools(): Promise<void> {
    const schools = await firstValueFrom(this._schoolService.getSchools(this.formlyModel.districts));
    const schoolOptions = schools.map(school => ({
      text: `${school.schoolNumber} - ${school.name}`,
      value: school.schoolId,
      selected: this.formlyModel.schools?.includes(school.schoolId) || schools.length === 1
    } as TdoeSelectItem));
    this.schoolOptions$.next(schoolOptions);
  }

  private async loadYears(): Promise<void> {
    const years = await firstValueFrom(this.sessionsService.getYears());
    const selectedYear = this.formlyModel.year.length > 0 ? this.formlyModel.year : [new Date().getFullYear().toString()];
    const yearOptions = years.map(year => ({
      text: year.toString(),
      value: year.toString(),
      selected: selectedYear.includes(year.toString())
    } as TdoeSelectItem));
    this.yearOptions$.next(yearOptions);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['districtIds'].currentValue) {
      this.loadDistricts();
    }
  }

  protected onSearchClick(): void {
    console.debug('StateScopeContextFilterComponent -> onSearchClick', { formlyModel: this.formlyModel });
    this.searchClick.emit({ 
      years: this.formlyModel.year,
      districtIds: this.formlyModel.districts, 
      schoolIds: this.formlyModel.schools,
      pageScope: this.formlyModel.schools
        ? PageScope.School 
        : this.formlyModel.districts
          ? PageScope.District
          : PageScope.State
    });
  }
}


