import { AfterViewInit, ChangeDetectorRef, Component, ViewChild, input, model, output, signal, computed } from '@angular/core';
import {  MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableDataModel } from 'app/models/table-data-model';
import { Router } from '@angular/router';
import { StringUtilities } from 'app/utilities/string-utilities/string-utilities';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { AdditionalInfoComponent } from '../additional-info/additional-info.component';
import { AdditionalInfoModel } from 'app/services/additional-info/additional-info.model';
import { additionalInfoFields } from './staff-table.additional-info-config';
import * as formlyConfig from './staff-table.formly-config';
import { debounceTime, distinctUntilChanged, tap, catchError, Observable } from 'rxjs';
import dayjs from 'dayjs';
import { MatSortModule, Sort } from '@angular/material/sort';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import { Dictionary } from 'app/models/dictionary';
import { ChipItem, TdoeButtonDirective, TdoeChipsComponent } from '@tdoe/design-system';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { StaffMember, StaffSearchTerms } from 'app/dto';
import { ExtendedFormlyFieldConfig } from 'app/models/extendedFormlyFieldConfig';
import { FeatureFlagDirective } from 'app/directives/feature-flag/feature-flag.directive';

@Component({
  selector: 'app-staff-table',
  templateUrl: './staff-table.component.html',
  styleUrls: ['./staff-table.component.scss', '../styles/table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    FormlyModule,
    FormlySelectModule,
    AdditionalInfoComponent,
    TdoeButtonDirective,
    TdoeChipsComponent,
    NgxSkeletonLoaderModule,
    MatMenuModule,
    FeatureFlagDirective
  ],
  host: { class: 'sword-table' } 
})
export class StaffTableComponent implements AfterViewInit {
  
  public readonly additionalInfoContextKey: string = 'staff-data-lookup';
  
  
  public totalRecords = input.required<number>();
  
  public pageIndex = input<number>(0);

  public staffMembers = input.required<StaffMember[] | undefined>();

  public searchTerms = model<StaffSearchTerms>({ });

  public pageChanged = output<TableDataModel.Pagination>();

  public sortClicked = output<TableDataModel.Sorting>();

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  protected readonly dynamicColumns = signal<AdditionalInfoModel.Field[]>([]);

  protected isLoading = true;

  public formlyFormGroup: FormGroup = new FormGroup({});

  public fields: FormlyFieldConfig[] = [];

  public readonly additionalInfoFields: AdditionalInfoModel.Category[] = additionalInfoFields;

  private readonly _staticFieldLabels = {
    nameFirst: 'First Name',
    nameMiddle: 'Middle Name',
    nameLast: 'Last Name',
    email: 'Staff Organization Email',
    district: 'District',
    school: 'School',
    gender: 'Gender',
    teacherLicenseNumber: 'Teacher License Number',
    licensureCheck: 'Licensure Check',
  };

  public fieldLabels: Record<string, string> = {
    ...this.additionalInfoFields
      .flatMap(category => category.fields)
      .reduce((acc, field) => ({
        ...acc,
        [field.key]: field.name
      }), {}),
      ...this._staticFieldLabels
  };

  protected filterChipItems = computed(() => 
    Object.entries(ObjectUtilities.flattenObject(this.searchTerms()))
      .map(([key, value]) => ({ key, value }))
      .filter(kvp => 
        this.searchTermHasValue(kvp.value) 
        // Fields that should not be shown in the filter chips 
        && !['schoolIds', 'districtIds', 'year', 'years', 'pageScope'].includes(kvp.key))
      .map(entry =>  ({
          text: `${this.fieldLabels[entry.key]}: '${this.isValidDate(entry.value) ? dayjs(entry.value).format('M-D-YYYY') : entry.value }'`,
          persistent: false,
          key: entry.key,
          id: entry.key
        } as ChipItem)));

  protected displayedColumns = computed(() => [
    ...this._staticColumns,
    ...this.dynamicColumns().map(field => field.key)
  ]);

  private readonly columnFilterConfigs = computed(() => {
    const configs: { [key: string]: ExtendedFormlyFieldConfig[] } = {};
    
    // Initialize all configs once
    Object.entries(this._columnFiltersConfig).forEach(([key, field]) => {
      const configField: ExtendedFormlyFieldConfig  = { ...field }; 
      
      if (configField && !configField.hooks?.onInit) {
        configField.hooks = {
          ...configField.hooks,
          onInit: (field: ExtendedFormlyFieldConfig): void => this.onFormlyFieldInit(field)
        };
      }
      
      configs[key] = [configField];
    });
    
    return configs;
  });

  private _staticColumns = Object.keys(this._staticFieldLabels);

  public readonly _columnFiltersConfig = formlyConfig.formlyFieldConfigs;

  public constructor(
    private readonly _router: Router,
    private readonly _changeDetector: ChangeDetectorRef
  ) {}

  public ngAfterViewInit(): void {
    this.isLoading = false;
    this._changeDetector.detectChanges();
  }

  protected onResetFiltersClick(): void {
    this.searchTerms.set({});
  }

  protected onPageChanged(pageEvent: PageEvent): void {
    this.pageChanged.emit({ 
      pageSize: pageEvent.pageSize, 
      pageIndex: pageEvent.pageIndex } as TableDataModel.Pagination);
  }

  protected onAdditionalInfoSelectionChanged(categories: AdditionalInfoModel.Category[]): void {
    const selectedAdditionalInfoFields = categories.flatMap(additionalInfoCategory => additionalInfoCategory.fields)
      .filter(field => field.selected)
      .map(field => field);
  
    this.dynamicColumns.set(selectedAdditionalInfoFields);
  }

  protected getColumnFilterConfig(key: string): FormlyFieldConfig[] {
    const config = this.columnFilterConfigs()[key];
    if (!config) {
      // Do not delete: this will warn us if there are any missing or misspelled keys.
      console.warn('Could not find formly config for key:', key);
      return [];
    }
    return config;
  }

  protected filterChipItemRemoved(filterChipItem: ChipItem): void {
    this.removeSearchTerm(filterChipItem.id);
  }
  
  protected onSortChanged(sort: Sort) : void {
    this.sortClicked.emit({
      sortColumn: sort.active,
      sortDirection: sort.direction
    } as TableDataModel.Sorting);
  }

  protected onRowClicked(staff: StaffMember): void {
    this._router.navigate([
      'data-lookup',
      'staff-view',
      staff.id,
      StringUtilities.FormatStringForUrl(`${staff.nameFirst}, ${staff.nameLast}, ${staff.nameMiddle}`)
    ]);
  }

  private onFormlyFieldInit(field: ExtendedFormlyFieldConfig): void {
    if (!field.formControl || field._initialized) {
      return;
    }
    
    field._initialized = true;
    
    field.formControl.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(val => {
        if (val) {
          //this.addFilterItemToSearchTerms(field, val);
          this.addFilterItemToSearchTerms();
        } else {
          this.removeSearchTerm(field.key as string);
        }
      }),
      catchError(err => {
        console.error('Error in form field processing', err);
        return new Observable();
      })
    ).subscribe();
  }

  private removeSearchTerm(filterItemKey: string): void {
    this.searchTerms.update(searchTerms => {
      const updatedSearchTerms: Dictionary = { ...ObjectUtilities.removeFalsyStringProperties(searchTerms) };
      delete updatedSearchTerms[filterItemKey];
      return updatedSearchTerms  as StaffSearchTerms;
    });
  }

  //private addFilterItemToSearchTerms(field: FormlyFieldConfig, value: unknown): void
  private addFilterItemToSearchTerms(): void {
    this.searchTerms.update(searchTerms => ({...searchTerms}));
  }
  //     {
  //       // Handle nested properties
  //       if (typeof field.key === 'string' && field.key?.includes('.')) {
  //         return {...searchTerms};
  //       }
  //       return {
  //         ...searchTerms,
  //         [field.key as string]: value
  //     };
  //   });
  // }

  public resolveNestedProperty(obj: unknown, path: string): unknown {
    if (!path) return undefined;
    return path
      .replace(/\[(\d+)\]/g, '.$1')
      .split('.')
      .reduce((acc: unknown, part: string): unknown => {
        if (acc && typeof acc === 'object' && part in (acc as Record<string, unknown>)) {
          return (acc as Record<string, unknown>)[part];
        }
        return undefined;
      }, obj);
  }

  protected trackByFn(index: number, staffMember: StaffMember):  string {
    return staffMember.id!;
  }
  
  //Clark: this is a duplicate of the method in the student-table.component.ts
  private isValidDate(value: unknown): value is Date {
    return value instanceof Date && !isNaN(value.getTime());
  }

  //Clark: this is a duplicate of the method in the student-table.component.ts
  private searchTermHasValue(searchTermValue: unknown): boolean{
    return Array.isArray(searchTermValue)? searchTermValue.length > 0 : !!searchTermValue;
  }
}