import { CommonModule } from '@angular/common';
import { Component, inject, Signal, signal } from '@angular/core';
import { ScopeContextFilterComponent } from 'app/components/shared/scope-context-filter/scope-context-filter.component';
import { SchoolTableComponent } from '../shared/school-table/school-table.component';
import { PagedResponse, TableDataModel } from 'app/models/table-data-model';
import { School, SchoolQuery } from 'app/dto';
import { SchoolService } from 'app/services/school/school.service';
import { UserService } from 'app/services/user/user.service';
import { UserProfile } from 'app/services/user/user.model';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, debounceTime, filter, map, switchMap, tap } from 'rxjs';
import { SelectedData } from 'app/components/shared/scope-context-filter/scope-context-filter.model';

@Component({
  selector: 'app-data-lookup-school',
  standalone: true,
  imports: [
    CommonModule,
    ScopeContextFilterComponent,
    SchoolTableComponent
  ],
  templateUrl: './data-lookup-school.component.html',
  styleUrls: ['./data-lookup-school.component.scss']
})
export class DataLookupSchoolComponent {

  private readonly tablePagination = signal({ pageIndex: 0, pageSize: 50} as TableDataModel.Pagination);
  private readonly tableSort = signal({} as TableDataModel.Sorting);

  private readonly schoolService = inject(SchoolService);
  private readonly userService = inject(UserService);

  protected userProfile: Signal<UserProfile | undefined>;

  protected isLoading = true;

  protected query = signal<SchoolQuery>({});
    
  protected readonly pagedResponse = toSignal(combineLatest([
    toObservable(this.query),
    toObservable(this.tablePagination),
    toObservable(this.tableSort)
  ]).pipe(
    debounceTime(300),
    map(([query, pagination, sorting]) => ({query, pagination, sorting})),
    switchMap(data => {
      return this.schoolService.searchSchools(data.query, data.pagination, data.sorting
      ).pipe(
        map((pagedResponse: PagedResponse<School>) => pagedResponse));
      })
  ));

  public constructor() {
    this.userProfile = toSignal<UserProfile>(this.userService.userProfile$.pipe(
      filter(userProfile => !!userProfile),
      tap(userProfile => { 
          this.query.update(query => ({
            ...query,
            schools: userProfile?.scopeDetails.schoolIds,
            districts: userProfile?.scopeDetails.schoolIds
          }));
          this.isLoading = false;
        })
      ));
  }

  public ngAfterContentInit(): void {
    this.isLoading = !this.userProfile();
  }

  protected onTableSortClicked(sorting: TableDataModel.Sorting): void {
    this.tableSort.set(sorting);
  }

  protected onTablePageChanged(pagination: TableDataModel.Pagination): void {
    this.tablePagination.set(pagination);
  }

  protected onContextFilterSearchClicked(selectedScopeContextFilterData: SelectedData): void {
    this.query.update(query => ({
      ...query,
      ...selectedScopeContextFilterData
    }));
  }

  protected onQuickSearch(schoolQuery: SchoolQuery): void {
    this.query.update(query => ({
      ...query,
      ...schoolQuery
    }));
  }
}
