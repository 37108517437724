import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoggingService, TdoeButtonComponent, TdoeButtonDirective, TdoeKpiCardComponent } from '@tdoe/design-system';
import { AdditionalInfoComponent } from '../shared/additional-info/additional-info.component';
import { School, SchoolReportingPeriod } from 'app/dto';
import { SchoolService } from 'app/services/school/school.service';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, tap } from 'rxjs';
import { AdditionalInfoModel } from 'app/services/additional-info/additional-info.model';
import { CalendarDaysComponent } from './calendar-days/calendar-days.component';
import { additionalInfoFields } from './school-view.additional-info-config';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import { Dictionary } from '@fullcalendar/core/internal';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-school-view',
  standalone: true,
  imports: [
    MatIconModule,
    AdditionalInfoComponent,
    MatExpansionModule,
    MatButtonToggleModule,
    AsyncPipe,
    CommonModule,
    RouterModule,
    MatTableModule,
    TdoeKpiCardComponent,
    TdoeButtonComponent,
    CalendarDaysComponent, 
    MatMenuModule,
    TdoeButtonDirective
],
  templateUrl: './school-view.component.html',
  styleUrl: './school-view.component.scss'
})
export class SchoolViewComponent implements OnInit {

  private readonly logger = inject(LoggingService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly schoolService = inject(SchoolService);
  private readonly titleService = inject(Title);

  private readonly additionalInfoFields$ = new BehaviorSubject<AdditionalInfoModel.Category[]>([]);
  private readonly calendarView$ = new BehaviorSubject<boolean>(false);
  private readonly school$ = new BehaviorSubject<School | undefined>(undefined);
  private readonly selectedReportingPeriod$ = new BehaviorSubject<SchoolReportingPeriod | undefined>(undefined);
 
  protected readonly additionalInfoFields: AdditionalInfoModel.Category[] = additionalInfoFields;

  private readonly _selectedAdditionalInfoFields: Observable<AdditionalInfoModel.Category[]> = 
    combineLatest([this.additionalInfoFields$, this.school$])
      .pipe(
        map(([categories, school]) => ({categories, school})),
        tap(data => { console.debug('Selected additional info fields:', data.categories); }),
        map((data) => {
          return data.categories
            .filter(category => category.fields.some(field => field.selected))
            .map(category => ({
              ...category,
              displayedColumns: category.fields.filter(field => field.selected).map(field => category.key? field.key.split('.').slice(-1)[0] : field.key),
              fields: category.fields
                .filter(field => field.selected)
                .map(field => ({
                  ...field,
                  key: category.key? field.key.split('.').slice(-1)[0] : field.key
                }))
            }));
        })
      );

  public readonly viewModel$ = combineLatest([this._selectedAdditionalInfoFields,
                                              this.school$.asObservable(),
                                              this.selectedReportingPeriod$.asObservable(),
                                              this.calendarView$.asObservable()])
      .pipe(
        map(([selectedAdditionalInfoFields, school, reportingPeriod, calendarView]) => ({ selectedAdditionalInfoFields, school, reportingPeriod, calendarView })),
        map(data => ({
          selectedAdditionalInfoFields: data.selectedAdditionalInfoFields,
          school: data.school,
          totalDays: (data.school?.additionalInformation?.schoolCalendarOverview?.abbreviatedDays ?? 0)
                   + (data.school?.additionalInformation?.schoolCalendarOverview?.discretionaryDays ?? 0)
                   + (data.school?.additionalInformation?.schoolCalendarOverview?.inServiceDays ?? 0)
                   + (data.school?.additionalInformation?.schoolCalendarOverview?.inServiceOptionalDays ?? 0)
                   + (data.school?.additionalInformation?.schoolCalendarOverview?.inclementWeatherDays ?? 0)
                   + (data.school?.additionalInformation?.schoolCalendarOverview?.instructionalDays ?? 0)
                   + (data.school?.additionalInformation?.schoolCalendarOverview?.makeUpDays ?? 0)
                   + (data.school?.additionalInformation?.schoolCalendarOverview?.parentTeacherConfDays ?? 0)
                   + (data.school?.additionalInformation?.schoolCalendarOverview?.professionalDevelopmentDays ?? 0)
                   + (data.school?.additionalInformation?.schoolCalendarOverview?.teacherVacationDays ?? 0)
                   + (data.school?.additionalInformation?.schoolCalendarOverview?.waivedDays ?? 0),
            reportingPeriodDisplayedColumns: [
              'periodNumber',
              'beginDate',
              'endDate',
              'numberOfDays',
            ],
            totalPeriodDays: data.school?.additionalInformation?.reportingPeriods?.reduce((td, p) => td + (p.numberOfDays ?? 0), 0) ?? 0,
            reportingPeriod: data.reportingPeriod ?? data.school?.additionalInformation?.reportingPeriods?.at(0),
            calendarDayDisplayedColumns: [
              'eventType',
              'eventDate',
              'duration',
              'schoolDayType'
            ],
            getEventTypeDisplay: (abbreviation: string): string => {
              switch (abbreviation) {
                case 'ID':
                  return 'Instructional Days';
                case 'AD':
                  return 'Abbreviated Days';
                case 'IS':
                  return 'In-Service Days';
                case 'IO':
                  return 'In-Service Optional Days';
                case 'M':
                  return 'Make-up Days';
                case 'O':
                  return 'Discretionary Days';
                case 'PT':
                  return 'Parent/Teacher Conference Days';
                case 'SI':
                  return 'Inclement Weather Days';
                case 'SP':
                  return 'Professional Development Days';
                case 'TV':
                  return 'Teacher Vacation Days';
                case 'WN':
                  return 'Waived Days';
                default:
                  return 'Other Holiday';
              }
            },
            viewCalendar: data.calendarView
        }))
      );
      

  private async LoadSchoolById(schoolId: string): Promise<void> {
    const school = await firstValueFrom(this.schoolService.getSchool(schoolId));
    this.school$.next(school);
    this.titleService.setTitle(`${this.titleService.getTitle()} - ${school.name}`);
  }

  public async ngOnInit(): Promise<void> {
    const school = this.school$.getValue();
      
    if (school) {
      this.titleService.setTitle(`${this.titleService.getTitle()} - ${school.name}`);
      return Promise.resolve();
    }

    const params = await firstValueFrom(this.route.params);
    if (params) {
      const schoolId: string = params['id'];
      if (schoolId) {
        this.LoadSchoolById(schoolId);
        return Promise.resolve();
      }
    }
  }

  public onFieldSelected(e: AdditionalInfoModel.Category[]): void {
    this.additionalInfoFields$.next(e);
  }

  public onReportingPeriodRowClicked(period: SchoolReportingPeriod): void {
    this.selectedReportingPeriod$.next(undefined);
    this.selectedReportingPeriod$.next(period);
  }
    
  public toggleCalendarView(): void {
    this.calendarView$.next(!this.calendarView$.getValue());
  }

  public getNestedProperty = (object: Dictionary, key: string): unknown => 
    ObjectUtilities.getNestedProperty(object, key);
}