@if (!isLoading) {
    <div class="container page-title">
        <div class="container">
            <h1>Class List</h1>
        </div>
    </div>
    <div class="container scope-context">
        <div class="container">
            <app-scope-context-filter 
                [userPageScope]="userProfile()!.pageScope"
                [selectedYears]="query().years" 
                [selectedSchoolIds]="query().schoolIds" 
                [selectedDistrictIds]="query().districtIds" 
                (searchClick)="onContextFilterSearchClicked($event)" />
        </div>
    </div>

    <div class="container quick-search">
        <div class="container">
            <app-class-filter [classQuery]="query()" (searchClicked)="onQuickSearch($event)" />
        </div>
    </div>

    <div class="container table">
        <div class="container">
            <app-class-table
                [classes]="pagedResponse()?.data"
                [totalRecords]="pagedResponse()?.totalRecords ?? 0"
                [(query)]="query"
                (pageChanged)="onTablePageChanged($event)"
                (sortClicked)="onTableSortClicked($event)"/>
        </div>
    </div>
}
