import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolDisplay',
  standalone: true
})
export class TdoeBoolDisplayPipe implements PipeTransform {

  public transform(value: boolean, ...args: unknown[]): string {
    let trueValue = true.toString();
    let falseValue = false.toString();

    if (args) {
      if (args.length > 1) {
        trueValue = args[0] as string;
        falseValue = args[1] as string;
      } else if (args.length > 0) {
        trueValue = args[0] as string;
      }
    }

    return value ? trueValue : falseValue;
  }

}
