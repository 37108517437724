import { AfterViewInit, ChangeDetectorRef, Component, computed, input, model, output, signal, ViewChild } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { AdditionalInfoComponent } from '../additional-info/additional-info.component';
import { CommonModule } from '@angular/common';
import { SsidFormatPipe } from 'app/pipes/ssid-format/ssid-format.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StringUtilities } from 'app/utilities/string-utilities/string-utilities';
import { Router } from '@angular/router';
import { TableDataModel } from 'app/models/table-data-model';
import { MatSortModule, Sort } from '@angular/material/sort';
import { catchError, debounceTime, distinctUntilChanged, Observable, tap } from 'rxjs';
import { ChipItem, TdoeButtonDirective, TdoeChipsComponent } from '@tdoe/design-system';
import { AdditionalInfoModel, Field } from 'app/services/additional-info/additional-info.model';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { AngularSvgIconModule } from 'angular-svg-icon';
import dayjs from 'dayjs';
import { Dictionary } from 'app/models/dictionary';
import { getNestedProperty, ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Student, StudentSearchTerms } from 'app/dto';
import { StudentTableFilterConfig } from './student-table-filter.config';
import { StudentTableCategoryConfig } from './student-table-category.config';
import { TdoeBoolDisplayPipe } from 'app/pipes/bool-display/tdoe-bool-display.pipe';
import { FeatureFlagDirective } from 'app/directives/feature-flag/feature-flag.directive';

interface ExtendedFormlyFieldConfig extends FormlyFieldConfig {
  _initialized?: boolean;
}

@Component({
  selector: 'app-student-table',
  templateUrl: './student-table.component.html',
  styleUrls: ['./student-table.component.scss', '../styles/table.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    FormlyModule,
    FormlySelectModule,
    AdditionalInfoComponent,
    TdoeButtonDirective,
    TdoeChipsComponent,
    ReactiveFormsModule,
    AngularSvgIconModule,
    NgxSkeletonLoaderModule,
    SsidFormatPipe,
    FeatureFlagDirective
  ],
  host: { class: 'sword-table' }
})
export class StudentTableComponent implements AfterViewInit {
  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  public additionalInfoContextKey = 'StudentTableComponent';
  public totalRecords = input.required<number>();
  public pageIndex = input<number>(0);
  public students = input.required<Student[] | undefined>();
  public searchTerms = model<StudentSearchTerms>({});
  public pageChanged = output<TableDataModel.Pagination>();
  public sortClicked = output<TableDataModel.Sorting>();

  protected readonly dynamicColumns = signal<AdditionalInfoModel.Field[]>([]);
  protected isLoading = true;
  protected readonly formlyFormGroup = new FormGroup({});
  protected readonly additionalInfoFields: AdditionalInfoModel.Category[] = StudentTableCategoryConfig;

  private readonly _staticFieldLabels: Dictionary = {
    nameLast: 'Last',
    nameFirst: 'First',
    nameMiddle: 'Middle',
    ssid: 'SSID',
    dateOfBirth: 'Date of Birth',
    grades: 'Grade',
    code: 'Code'
  };

  private _fieldLabels: Dictionary = {
    ...this.additionalInfoFields
      .flatMap(category => category.fields)
      .reduce((acc, field) => ({ ...acc, [field.key]: field.name }), {}),
    ...this._staticFieldLabels
  };

  private boolPipe = new TdoeBoolDisplayPipe();
  private _chipIdCounter = 0;
  private generateUniqueChipId(): string {
    return `chip-${this._chipIdCounter++}`;
  }

  protected filterChipItems = computed<ChipItem[]>(() =>
    Object.entries(this.searchTerms())
      .map(([key, value]) => ({ key, value }))
      .filter(
        kvp =>
          this.searchTermHasValue(kvp.value) &&
          // Fields that should not be shown in the filter chips
          !['schoolIds', 'districtIds', 'year', 'years', 'pageScope'].includes(kvp.key)
      )
      .map(entry => {
        const formattedValue = this.isValidDate(entry.value)
          ? dayjs(entry.value).format('M-D-YYYY')
          : entry.value;
        return {
          text: `${this._fieldLabels[entry.key]}: '${formattedValue}'`,
          persistent: false,
          id: `${this.generateUniqueChipId()}|${entry.key}`
        } as ChipItem;
      })
  );

  protected displayedColumns = computed(() => [
    ...this._staticColumns,
    ...this.dynamicColumns().map(field => field.key),
    'school',
    'district'
  ]);

  private readonly columnFilterConfigs = computed(() => {
    const configs: { [key: string]: ExtendedFormlyFieldConfig[] } = {};
    Object.entries(this._columnFiltersConfig).forEach(([key, field]) => {
      const configField: ExtendedFormlyFieldConfig = { ...field };
      if (configField && !configField.hooks?.onInit) {
        configField.hooks = {
          ...configField.hooks,
          onInit: (f: ExtendedFormlyFieldConfig): void => this.onFormlyFieldInit(f)
        };
      }
      configs[key] = [configField];
    });
    return configs;
  });

  private readonly _staticColumns = [
    'nameLast',
    'nameFirst',
    'nameMiddle',
    'ssid',
    'dateOfBirth',
    'grade'
  ];

  private readonly _columnFiltersConfig: { [key: string]: FormlyFieldConfig } =
    StudentTableFilterConfig;

  public constructor(private readonly _router: Router, private readonly _changeDetector: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.isLoading = false;
    this._changeDetector.detectChanges();
  }

  protected onResetFiltersClick(): void {
    this.searchTerms.set({});
  }

  protected onPageChanged(pageEvent: PageEvent): void {
    this.pageChanged.emit({
      pageSize: pageEvent.pageSize,
      pageIndex: pageEvent.pageIndex
    } as TableDataModel.Pagination);
  }

  protected onAdditionalInfoSelectionChanged(categories: AdditionalInfoModel.Category[]): void {
    const selectedAdditionalInfoFields = categories
      .flatMap(additionalInfoCategory => additionalInfoCategory.fields)
      .filter(field => field.selected)
      .map(field => field);
    this.dynamicColumns.set(selectedAdditionalInfoFields);
  }

  protected getColumnFilterConfig(key: string): FormlyFieldConfig[] {
    const config = this.columnFilterConfigs()[key];
    if (!config) {
      console.warn('Could not find formly config for key:', key);
      return [];
    }
    return config;
  }

  protected filterChipItemRemoved(chip: ChipItem): void {
    const separatorIndex = chip.id.indexOf('|');
    if (separatorIndex > -1) {
      const searchTermKey = chip.id.substring(separatorIndex + 1);
      this.removeSearchTerm(searchTermKey);
    }
  }

  protected onSortChanged(sort: Sort): void {
    this.sortClicked.emit({
      sortColumn: sort.active,
      sortDirection: sort.direction
    } as TableDataModel.Sorting);
  }

  protected onRowClicked(student: Student): void {
    this._router.navigate([
      'data-lookup',
      'student-view',
      student.id,
      StringUtilities.FormatStringForUrl(
        `${student.nameFirst}, ${student.nameLast}, ${student.nameMiddle}`
      )
    ]);
  }

  public resolveNestedProperty(student: Student, col: Field): unknown {
    let result = getNestedProperty(student, col.valuePath ?? col.key);
    if (typeof result === 'boolean') {
      result = this.boolPipe.transform(result, 'Yes', 'No');
    }
    return result;
  }

  private onFormlyFieldInit(field: ExtendedFormlyFieldConfig): void {
    if (!field.formControl || field._initialized) {
      return;
    }
    field._initialized = true;

    field.formControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(val => {
          if (val) {
            this.addFilterItemToSearchTerms(field, val);
          } else {
            this.removeSearchTerm(field.key as string);
          }
        }),
        catchError(err => {
          console.error('Error in form field processing', err);
          return new Observable();
        })
      )
      .subscribe();
  }

  private removeSearchTerm(filterItemKey: string): void {
    this.searchTerms.update(searchTerms => {
      const updatedSearchTerms: Dictionary = {
        ...ObjectUtilities.removeFalsyStringProperties(searchTerms)
      };
      delete updatedSearchTerms[filterItemKey];
      return updatedSearchTerms as StudentSearchTerms;
    });
  }

  private addFilterItemToSearchTerms(field: FormlyFieldConfig, value: unknown): void {
    this.searchTerms.update(searchTerms => ({
      ...searchTerms,
      [field.key as string]: value
    }));
  }

  private isValidDate(value: unknown): value is Date {
    return value instanceof Date && !isNaN(value.getTime());
  }

  private searchTermHasValue(searchTermValue: unknown): boolean {
    return Array.isArray(searchTermValue)
      ? searchTermValue.length > 0
      : !!searchTermValue;
  }
}