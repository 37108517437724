import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HttpParamsUtilities } from '../../utilities/http-params-utilities/http-params-utilities';
import { StaffMember, StaffSearchTerms } from '../../dto';
import { PagedResponse, Pagination, Sorting } from 'app/models';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';

export { StaffModel } from './staff.model';


@Injectable({
  providedIn: 'root'
})
export class StaffService {

  public constructor(private http: HttpClient) { }

  private staffUrl = `${environment.apiBaseUrl}Staff`;

  public getStaffBySearchTerms(staffSearchTerms: StaffSearchTerms, pagination: Pagination, sorting: Sorting): Observable<PagedResponse<StaffMember>> {
    const flattenedStaffSearchTerms = ObjectUtilities.flattenObject(staffSearchTerms);

    const httpParams = HttpParamsUtilities.toHttpParams(flattenedStaffSearchTerms, pagination, sorting);
    
    return this.http.get<PagedResponse<StaffMember>>(`${this.staffUrl}/StaffMembers`, {  params: httpParams });
  }

  public getStaffMember(staffMemberId: string): Observable<StaffMember> {
    return this.http.get<StaffMember>(`${this.staffUrl}/${staffMemberId}`);
  }

  private processDateFields(searchTerms: StaffSearchTerms): StaffSearchTerms {
    return Object.entries(searchTerms).reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value instanceof Date ? value.toISOString().split('T')[0] : value
    }), {} as StaffSearchTerms);
  }
}
