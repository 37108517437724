import { FormlyFieldConfig } from '@ngx-formly/core';

export const SchoolTableConfig: { [key: string]: FormlyFieldConfig } = {
    'name': {
      key: 'name',
      type: 'tdoe-input',
      props: {
        label: 'School Name'
      }
    },
    'schoolNumber': {
      key: 'schoolNumber',
      type: 'tdoe-input',
      props: {
        label: 'School Number'
      }
    },
    'calendarName': {
      key: 'calendarName',
      type: 'tdoe-select',
      props: {
        label: 'Calendar Name',
        options: [
          {
            label: 'Traditional',
            value: 'Traditional'
          },
          {
            label: 'Pull-Out',
            value: 'Pull-Out'
          },
          {
            label: 'Conditional',
            value: 'Conditional'
          }
        ],
        multiple: true
      }
    },
    'scheduledDays': {
      key: 'scheduledDays',
      type: 'tdoe-select',
      props: {
        label: 'Scheduled Days',
        options: [
          {
            label: 'M-F',
            value: 'M-F'
          },
          {
            label: 'M-S',
            value: 'M-S'
          }
        ],
        multiple: true
      }
    }
  };