import { Component, computed, input, output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { FormlyMatSelectModule } from '@ngx-formly/material/select';
import { ClassQuery } from 'app/dto';
import { TdoeAccordionDirective, TdoeButtonDirective } from '@tdoe/design-system';
import { MatExpansionModule } from '@angular/material/expansion';
import { ObjectUtilities } from 'app/utilities/object-utilities/object-utilities';
import { ClassFilterConfig } from './class-filter.config';

@Component({
  selector: 'app-class-filter',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatSelectModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule,
    TdoeButtonDirective,
    TdoeAccordionDirective,
    MatExpansionModule
  ],
  templateUrl: './class-filter.component.html',
  styleUrl: './class-filter.component.scss'
})
export class ClassFilterComponent {

  public searchClicked = output<ClassQuery>();
  
  public classQuery = input<ClassQuery>({});

  public combinedQuery = computed(() =>({...this.classQuery()}));

  protected formlyFormGroup = new FormGroup({});

  protected formlyFieldConfigs: FormlyFieldConfig[] = ClassFilterConfig;

  protected onSearchClick(): void {
    this.searchClicked.emit(ObjectUtilities.removeFalsyStringProperties(this.formlyFormGroup.value));
  }
}
