<mat-accordion tdoe-accordion>
    <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
            <mat-panel-title>Quick Search</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="search-terms">
            <form [formGroup]="formlyFormGroup">
                <formly-form [form]="formlyFormGroup" [model]="combinedSearchTerms()" [fields]="formlyFieldConfigs"/>
                <div class="action-row">
                    <button tdoe-button id="search-button"  (click)="onSearchClick()">Search</button>
                </div>
            </form>
        </div>
    </mat-expansion-panel>
</mat-accordion>