@if(!isLoading){
    <div class="container page-title">
        <div class="container">
            <h1>Staff List</h1>
        </div>
    </div>
    
    <div class="container scope-context">
        <div class="container">
            <app-scope-context-filter
                [userPageScope]="userProfile()!.pageScope"
                [selectedYears]="searchTerms().years"
                [selectedSchoolIds]="searchTerms().schoolIDs"
                [selectedDistrictIds]="searchTerms().districtIDs"
                (searchClick)="onContextFilterSearchClicked($event)">
            </app-scope-context-filter>
        </div>
    </div>

    <div class="container staff-filter">
        <div class="container">
            <app-staff-filter 
                [staffSearchTerms]="searchTerms()"
                (searchClicked)="onStaffFilterSearchClicked($event)">
            </app-staff-filter>
        </div>
    </div>

    <div class="container table">
        <div class="container"> 
            <app-staff-table 
                [staffMembers]="staffMembersPagedResponse()?.data ?? undefined"
                [totalRecords]="staffMembersPagedResponse()?.totalRecords ?? 0"
                [pageIndex]="staffMembersPagedResponse()?.pageIndex ?? 0"
                [(searchTerms)]="searchTerms"
                (pageChanged)="onStaffTablePageChanged($event)"
                (sortClicked)="onStaffTableSortClicked($event)">
            </app-staff-table>
        </div> 
    </div>
}