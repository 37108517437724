import { ExtendedFormlyFieldConfig } from 'app/models/extendedFormlyFieldConfig';

export const formlyFieldConfigs: Record<string, ExtendedFormlyFieldConfig> = {
  nameFirst: {
    key: 'nameFirst',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  nameMiddle: {
    key: 'nameMiddle',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  nameLast: {
    key: 'nameLast',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  email: {
    key: 'email',
    type: 'input',
    props: {
      type: 'email'
    }
  },
  district: {
    key: 'district',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  school: {
    key: 'school',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  gender: {
    key: 'gender',
    type: 'select',
    props: {
      options: [
        { label: '' },
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' }
      ],
      

    }
  },
  teacherLicenseNumber: {
    key: 'teacherLicenseNumber',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  licensureCheck: {
    key: 'licensureCheck',
    type: 'select',
    props: {
      options: [
        { label: ''},
        { label: 'N=New Teacher', value: 'N' },
        { label: 'R=ROTC', value: 'R' },
        { label: 'U=University Staff', value: 'U' },
        { label: 'O=Other', value: 'O' },
        { label: 'E=IEA Teacher', value: 'E' }
      ]
    }
  },
  // ----------------------------------
  // Dynamic Columns Filters - Staff Current Assignments
  // ----------------------------------
  'additionalInfo.staffAssignments[0].assignmentCode': {
    key: 'additionalInfo.staffAssignments[0].assignmentCode',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  'assignmentBeginDate': {
    key: 'assignmentBeginDate',
    type: 'datepicker'
  },
  'assignmentEndDate': {
    key: 'assignmentEndDate',
    type: 'datepicker'
  },
  // ----------------------------------
  // Dynamic Columns Filters - Staff Class Assignments
  // ----------------------------------
  'additionalInfo.staffAssignments[0].assignmentStart': {
  key: 'additionalInfo.staffAssignments[0].assignmentStart',
  type: 'datepicker'
  },
  'additionalInfo.staffAssignments[0].assignmentEnd': {
    key: 'additionalInfo.staffAssignments[0].assignmentEnd',
    type: 'datepicker'
  },
  'additionalInfo.staffAssignments[0].classStart': {
    key: 'additionalInfo.staffAssignments[0].classStart',
    type: 'datepicker'
  },
  'additionalInfo.staffAssignments[0].classEnd': {
    key: 'additionalInfo.staffAssignments[0].classEnd',
    type: 'datepicker'
  },
  'additionalInfo.staffAssignments[0].courseCode': {
    key: 'additionalInfo.staffAssignments[0].courseCode',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  'additionalInfo.staffAssignments[0].localClassNumber': {
    key: 'additionalInfo.staffAssignments[0].localClassNumber',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  'additionalInfo.staffAssignments[0].teacherOfRecord': {
    key: 'additionalInfo.staffAssignments[0].teacherOfRecord',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  'additionalInfo.staffAssignments[0].periodDuration': {
    key: 'additionalInfo.staffAssignments[0].periodDuration',
    type: 'select',
    props: {
      type: 'text'
    }
  },
  // ----------------------------------
  // Dynamic Columns Filters - Staff Licensure & Endorsement Details
  // ----------------------------------
  'experienceInYears': {
    key: 'experienceInYears',
    type: 'input',
    props: {
      type: 'number'
    }
  },
  'educationLevel': {
    key: 'educationLevel',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  'conferralDate': {
    key: 'conferralDate',
    type: 'datepicker'
  },
  'addedToFile': {
    key: 'addedToFile',
    type: 'datepicker'
  },
  'additionalInfo.staffMemberLicensureDetails.dateIssued': {
    key: 'additionalInfo.staffMemberLicensureDetails.dateIssued',
    type: 'datepicker'
  },
  'additionalInfo.staffMemberLicensureDetails.type': {
    key: 'additionalInfo.staffMemberLicensureDetails.type',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  'additionalInfo.staffMemberLicensureDetails.description': {
    key: 'additionalInfo.staffMemberLicensureDetails.description',
    type: 'input',
    props: {
      type: 'text'
    }
  },
  'additionalInfo.staffMemberLicensureDetails.expirationDate': {
    key: 'additionalInfo.staffMemberLicensureDetails.expirationDate',
    type: 'datepicker'
  },
  'additionalInfo.staffMemberLicensureDetails.renewedDate': {
    key: 'additionalInfo.staffMemberLicensureDetails.renewedDate',
    type: 'datepicker'
  },
  'additionalInfo.staffMemberLicensureDetails.numberOfRenewals': {
    key: 'additionalInfo.staffMemberLicensureDetails.numberOfRenewals',
    type: 'input',
    props: {
      type: 'text'
    }
  }
};