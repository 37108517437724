@if (isLoading || !classes()) {
    <div class="skeleton-buttons">
        <ngx-skeleton-loader [theme]="{ 'width':'30%', 'min-width': '9rem','height': '2.5rem' }" count="3" appearance="line"/>
    </div>
    <ngx-skeleton-loader [theme]="{ 'height': '2rem' }" count="1" appearance="line"/>
    <ngx-skeleton-loader count="25" appearance="line"/>
} @else {
    <div class="header">
        @if (filterChipItems() && filterChipItems().length > 0) {
            <tdoe-chips [chips]="filterChipItems()" (onChipRemove)="filterChipItemRemoved($event)"/>
        }
        <div class="controls">
            <div class="buttons">
                @if (enableAdditionalInfo) {
                    <app-additional-info
                    [additionalInfoFields]="additionalInfoFields"
                    [contextKey]="additionalInfoContextKey"
                    (fieldSelected)="onAdditionalInfoSelectionChanged($event)" />
                }
                <button tdoe-button size="large" (click)="onResetFiltersClick()" >Reset Filters</button>
                <button tdoe-button size="large" [matMenuTriggerFor]="menu">Export</button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item>CSV</button>
                    <button mat-menu-item>PDF</button>
                    <button mat-menu-item>Excel</button>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="table-title">
        <span>Class Section List</span>
    </div>
    <div class="scrollable-container">
        <mat-table matSort (matSortChange)="onSortChanged($event)" [dataSource]="classes()!">

            <ng-container matColumnDef="courseType">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    @if (enableColumnFilters) {
                        <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                            <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('courseType')" [model]="query()"></formly-form>
                        </form>
                    } @else {
                        <span>Course Type</span>
                    }
                </mat-header-cell>
                <mat-cell *matCellDef="let class"> {{class.courseType}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="courseCode">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    @if (enableColumnFilters) {
                        <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                            <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('courseCode')" [model]="query()"></formly-form>
                        </form>
                    } @else {
                        <span>Course Code</span>
                    }
                </mat-header-cell>
                <mat-cell *matCellDef="let class"> {{class.courseCode}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="localClassNumber">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    @if (enableColumnFilters) {
                        <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                            <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('localClassNumber')" [model]="query()"></formly-form>
                        </form>
                    } @else {
                        <span>Local Class Number</span>
                    }
                </mat-header-cell>
                <mat-cell *matCellDef="let class"> {{class.localClassNumber}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sectionIdentifier">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    @if (enableColumnFilters) {
                        <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                            <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('sectionIdentifier')" [model]="query()"></formly-form>
                        </form>
                    } @else {
                        <span>Section Identifier</span>
                    }
                </mat-header-cell>
                <mat-cell *matCellDef="let class"> {{class.sectionIdentifier}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="classStartDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    @if (enableColumnFilters) {
                        <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                            <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('classStartDate')" [model]="query()"></formly-form>
                        </form>
                    } @else {
                        <span>Class Begin Date</span>
                    }
                </mat-header-cell>
                <mat-cell *matCellDef="let class"> {{class.classStartDate}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="classEndDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    @if (enableColumnFilters) {
                        <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                            <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('classEndDate')" [model]="query()"></formly-form>
                        </form>
                    } @else {
                        <span>Class End Date</span>
                    }
                </mat-header-cell>
                <mat-cell *matCellDef="let class"> {{class.classEndDate}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="classType">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    @if (enableColumnFilters) {
                        <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                            <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('classType')" [model]="query()"></formly-form>
                        </form>
                    } @else {
                        <span>Class Type</span>
                    }
                </mat-header-cell>
                <mat-cell *matCellDef="let class"> {{class.classType}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="teachingMethod">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    @if (enableColumnFilters) {
                        <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                            <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('teachingMethod')" [model]="query()"></formly-form>
                        </form>
                    } @else {
                        <span>Teaching Method</span>
                    }
                </mat-header-cell>
                <mat-cell *matCellDef="let class"> {{class.teachingMethod}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="testWindow">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    @if (enableColumnFilters) {
                        <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                            <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig('testWindow')" [model]="query()"></formly-form>
                        </form>
                    } @else {
                        <span>Test Window</span>
                    }
                </mat-header-cell>
                <mat-cell *matCellDef="let class"> {{class.testWindow}} </mat-cell>
            </ng-container>

            @if (dynamicColumns().length > 0) {
                @for (col of dynamicColumns(); track col)
                {
                    <ng-container [matColumnDef]="col.key">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            @if (enableColumnFilters) {
                                <form class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                                    <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig(col.key)" [model]="query()"></formly-form>
                                </form>
                            } @else {
                                <span></span>
                            }
                        </mat-header-cell>
                        <mat-cell *matCellDef="let class"> {{class[col.key]}} </mat-cell>
                    </ng-container>
                }
            }

            <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
            <mat-row *matRowDef="let class; columns: displayedColumns()" [attr.data-id]="class.id" [routerLink]="['/data-lookup/class', class.id]" [queryParams]="{ courseName: formatClassName(class) }" [state]="class"></mat-row>
                    
            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching applied filters</td>
            </tr>
        </mat-table>
    </div>
    <mat-paginator
        #paginator
        [length]="totalRecords()"
        pageSize="50"
        [pageSizeOptions]="[25, 50, 100, 500, 1000]"
        showFirstLastButtons
        (page)="onPageChanged($event)">
    </mat-paginator>
}