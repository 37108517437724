@if (isLoading || !staffMembers()) {
  <div class="skeleton-buttons">
      <ngx-skeleton-loader [theme]="{ 'width':'30%', 'min-width': '9rem','height': '2.5rem' }" count="3" appearance="line"/>
  </div>
  <ngx-skeleton-loader [theme]="{ 'height': '2rem' }" count="1" appearance="line"/>
  <ngx-skeleton-loader count="25" appearance="line"/>
}
@else {
  <div class="header">
    @if (filterChipItems() && filterChipItems().length > 0) {
      <tdoe-chips [chips]="filterChipItems()" (onChipRemove)="filterChipItemRemoved($event)"/>
    } @else {
        <h3></h3>
    }
    <div class="controls">
      <div class="buttons">
        <app-additional-info
            [additionalInfoFields]="additionalInfoFields" 
            [contextKey]="additionalInfoContextKey"
            (fieldSelected)="onAdditionalInfoSelectionChanged($event)"/>
        <button tdoe-button size="large" (click)="onResetFiltersClick()" >Reset Filters</button>
        <button tdoe-button size="large" [matMenuTriggerFor]="menu">Export</button>
        <mat-menu #menu="matMenu" xPosition="before">
          <button mat-menu-item>CSV</button>
          <button mat-menu-item>PDF</button>
          <button mat-menu-item>Excel</button>
        </mat-menu>
      </div>
    </div>
  </div>
  
  <div class="table-title">
    <span>Staff Information</span>
  </div>
  <div class="scrollable-container">
    <mat-table matSort  [dataSource]="staffMembers()!" (matSortChange)="onSortChanged($event)" [trackBy]="trackByFn">
        @if (displayedColumns().length > 0) {
          @for (columnKey of displayedColumns(); track columnKey) {
            <ng-container [matColumnDef]="columnKey">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                  <div>{{fieldLabels[columnKey]}}</div>
                    <form *featureFlag="'staffDLColumnFilters'" class="filter-form" [formGroup]="formlyFormGroup" (click)="$event.stopImmediatePropagation()">
                        <formly-form [form]="formlyFormGroup" [fields]="getColumnFilterConfig(columnKey)" [model]="searchTerms()"></formly-form>
                    </form>
                </mat-header-cell>
                <mat-cell *matCellDef="let staffMember"> {{resolveNestedProperty(staffMember,columnKey)}} </mat-cell>
            </ng-container>
          }
        }
        
      <mat-header-row *matHeaderRowDef="displayedColumns()"></mat-header-row>
      <mat-row mat-row *matRowDef="let staff; columns: displayedColumns()" 
          [attr.data-staff-id]="staff.id" 
          (click)="onRowClicked(staff)">
      </mat-row>

      <!-- Row shown when there is no matching data. -->
      <mat-row class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching applied filters</td>
      </mat-row>
    </mat-table>
  </div>

  <mat-paginator
    #paginator
    [pageIndex]="pageIndex()"
    [length]="totalRecords()"
    pageSize="50"
    [pageSizeOptions]="[25, 50, 100, 500, 1000]"
    showFirstLastButtons
    (page)="onPageChanged($event)">
  </mat-paginator>
}