import { Component, DestroyRef, OnInit } from '@angular/core';
import { AdditionalInfoComponent } from '../shared/additional-info/additional-info.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StudentService } from 'app/services/student/student.service';
import { AdditionalInfoService } from 'app/services/additional-info/additional-info.service';
import { BehaviorSubject, Observable, combineLatest, filter, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { EnrollmentClassificationComponent } from './enrollment-classification/enrollment-classification.component';
import { EnrollmentHistoryComponent } from './enrollment-history/enrollment-history.component';
import { getNestedProperty } from 'app/utilities/object-utilities/object-utilities';
import { StudentViewConfiguration } from './student-view.config';
import { Category, Field } from 'app/services/additional-info/additional-info.model';
import { TdoeBoolDisplayPipe } from 'app/pipes/bool-display/tdoe-bool-display.pipe';
import { MatTableModule } from '@angular/material/table';
import { TdoeButtonDirective } from '@tdoe/design-system';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-student-view',
  standalone: true,
  imports: [
    AdditionalInfoComponent,
    MatExpansionModule,
    MatIconModule,
    CommonModule,
    MatTabsModule,
    EnrollmentClassificationComponent,
    EnrollmentHistoryComponent,
    RouterModule,
    MatTableModule,
    TdoeButtonDirective, 
    MatMenuModule
],
  templateUrl: './student-view.component.html',
  styleUrl: './student-view.component.scss'
})
export class StudentViewComponent implements OnInit {

  protected readonly additionalInfoContextKey = 'StudentViewComponent';

  private readonly _categoriesSubject = new BehaviorSubject<Category[]>([]);
  private readonly _categories$: Observable<Category[]> = this._categoriesSubject.asObservable();

  private readonly _studentId = this.route.snapshot.paramMap.get('id') ?? '0';
  private readonly _student$ = this.studentService.getStudent(this._studentId);

  private readonly _studentCategories$: Observable<Category[]> =
    combineLatest([this._student$, this._categories$])
      .pipe(
        map(([student, categories]) => {
          return categories
            .filter(category => category.fields.some(field => field.selected))
            .map(category => ({
              ...category,
              fields: category.fields
                .filter(field => field.selected)
                .map(field => ({
                  ...field,
                  value: this.resolveNestedProperty(student, field)
                }))
            }));
        })
      );

  public additionalInfoFields: Category[] = StudentViewConfiguration;
    
  protected viewModel$ = combineLatest([this._student$, this._studentCategories$])
    .pipe(
      map(([student, studentCategories]) => ({ student, studentCategories })),
      map(data => ({
        student: data.student,
        studentCategories: data.studentCategories,
        staticSections: data.studentCategories.filter(_ => !['Club Membership'].includes(_.name)),
        clubMembershipCategory: this.getCategory(data.studentCategories, 'Club Membership'),
        clubMembershipColumns: this.getCategory(data.studentCategories, 'Club Membership')?.fields.map(_ => _.key)
      }))
    );

  private boolPipe = new TdoeBoolDisplayPipe();

  public constructor (
    private readonly route: ActivatedRoute,
    private readonly titleService: Title,
    private readonly studentService: StudentService,
    private readonly additionalInfoService: AdditionalInfoService,
    private readonly destroyReference: DestroyRef
  ){}

  public getCategory(categories: Category[], categoryName: string): Category | undefined {
    return categories.find(_ => _.name === categoryName && _.fields.some(field => field.selected));
  }

  public ngOnInit(): void {
    this.titleService.setTitle(`${this.titleService.getTitle()} - ${this.route.snapshot.paramMap.get('name')}`);
    
    //Clark remove subscribe from class file
    this.additionalInfoService.getFieldState(this.additionalInfoContextKey)
      .pipe(
        takeUntilDestroyed(this.destroyReference),
        filter(categories => !!categories)
      )
      .subscribe(categories => this._categoriesSubject.next(categories));
  }

  public onFieldSelected(categories: Category[]): void {
    console.debug('StudentViewComponent -> onFieldSelected', { categories });
    this._categoriesSubject.next(categories);
  }

  public resolveNestedProperty(student: unknown, col: Field): unknown {
    let result = getNestedProperty(student, col.valuePath ?? col.key);

    if (typeof result == 'boolean') {
      result = this.boolPipe.transform(result, 'Yes', 'No');
    }

    return result;
  }

}
