import { Injectable, signal } from '@angular/core';
import { environment } from 'environments/environment';
import { FeatureFlagModel } from './feature-flag.model';
import { Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  private _features = signal<Record<string, boolean>>({});

  public getFeatureFlags(): Observable<FeatureFlagModel.FeatureFlags> {
    // Integrate with a feature flag management tool to retrieve the feature flags here, if you are so inclined 🧐.
    return of(environment.featureFlags as FeatureFlagModel.FeatureFlags).pipe(tap(features => this._features.set(features as unknown as Record<string, boolean>)));
  }

  public getFeature(feature: FeatureFlagModel.FeatureFlagKeys): boolean {
    return this._features()[feature] ?? false;
  }
}
