import { FormlyFieldConfig } from '@ngx-formly/core';

export const ClassTableConfig: { [key: string]: FormlyFieldConfig } = {
    courseType: {
      key: 'courseType',
      type: 'tdoe-select',
      props: {
        label: 'Course Type',
        options: [
          {
            label: 'General',
            value: 'General'
          },
          {
            label: 'CTE',
            value: 'CTE'
          },
          {
            label: 'Special Populations',
            value: 'Special Populations'
          },
          {
            label: 'Special Course',
            value: 'Special Course'
          }
        ]
      }
    },
    courseCode: {
      key: 'courseCode',
      type: 'tdoe-input',
      props: {
        label: 'Course Code'
      }
    },
    localClassNumber: {
      key: 'localClassNumber',
      type: 'tdoe-input',
      props: {
        label: 'Local Class Number'
      }
    },
    sectionIdentifier: {
      key: 'sectionIdentifier',
      type: 'tdoe-select',
      props: {
        label: 'Section Identifier',
        options: [
          {
            label: ' ',
            value: undefined
          },
          {
            label: '01',
            value: '01'
          },
          {
            label: '02',
            value: '02'
          },
          {
            label: '03',
            value: '03'
          },
          {
            label: '04',
            value: '04'
          },
          {
            label: '05',
            value: '05'
          },
          {
            label: '06',
            value: '06'
          },
          {
            label: '07',
            value: '07'
          },
          {
            label: '08',
            value: '08'
          },
          {
            label: '09',
            value: '09'
          },
          {
            label: '10',
            value: '10'
          }
        ]
      }
    },
    classStartDate: {
      key: 'classStartDate',
      type: 'tdoe-date',
      props: {
        label: 'Class Begin Date'
      }
    },
    classEndDate: {
      key: 'classEndDate',
      type: 'tdoe-date',
      props: {
        label: 'Class End Date'
      }
    },
    classType: {
      key: 'classType',
      type: 'tdoe-select',
      props: {
        label: 'Class Type',
        options: [
          {
            label: 'Conditional',
            value: 'C'
          },
          {
            label: 'Pull-Out',
            value: 'P'
          },
          {
            label: 'Traditional',
            value: 'T'
          }
        ]
      }
    },
    teachingMethod: {
      key: 'teachingMethod',
      type: 'tdoe-select',
      props: {
        label: 'Teaching Method',
        options: [
          {
            label: 'A - Asynchronous Virtual Course',
            value: 'A'
          },
          {
            label: 'B - Bisynchronous Virtual Course',
            value: 'B'
          },
          {
            label: 'D - Distance Learning',
            value: 'D'
          },
          {
            label: 'H - Hybrid Virtual Course',
            value: 'H'
          },
          {
            label: 'S - Synchronous Virtual Course',
            value: 'S'
          },
          {
            label: 'T - Traditional',
            value: 'T'
          }
        ]
      }
    },
    testWindow: {
      key: 'testWindow',
      type: 'tdoe-select',
      props: {
        label: 'Test Window',
        options: [
          {
            label: 'Traditional',
            value: 'T - Traditional'
          },
          {
            label: 'Fall',
            value: 'F - Fall'
          },
          {
            label: 'Spring',
            value: 'S - Spring'
          }
        ]
      }
    }
  };