import { Category } from 'app/services/additional-info/additional-info.model';

export const StudentTableCategoryConfig: Category[] = [
    {
      name: 'Student Demographic',
      expanded: true,
      fields: [
        {
          name: 'Immigrant',
          key: 'immigration',
          valuePath: 'demographics.immigration',
          selected: false
        },
        {
          name: 'Date First Enrolled in US School',
          key: 'dateFirstEnrolledInPublicSchool',
          valuePath: 'demographics.dateFirstEnrolledInUsSchool',
          selected: false
        },
        {
          name: 'Year Entered 9th Grade',
          key: 'yearEntered9thGrade',
          valuePath: 'demographics.yearEnteredGrade9',
          selected: false
        },
        {
          name: 'Native Language',
          key: 'nativeLanguage',
          valuePath: 'demographics.nativeLanguage',
          selected: false
        },
        {
          name: 'EL Service Years',
          key: 'elServiceYears',
          valuePath: 'demographics.elServiceYears',
          selected: false
        },
        {
          name: 'Calculated EL Service Years',
          key: 'calculatedElServiceYears',
          valuePath: 'demographics.calculatedElServiceYears',
          selected: false
        },
        {
          name: 'English Language Background',
          key: 'englishLanguageBackground',
          valuePath: 'demographics.englishLanguageBackground',
          selected: false
        },
        {
          name: 'Race',
          key: 'race',
          valuePath: 'demographics.race',
          selected: false
        }
      ]
    },
    {
      name: 'Enrollment Info',
      expanded: true,
      fields: [
        {
          name: 'Enrollment Date',
          key: 'enrollmentStartDate',
          selected: false
        },
        {
          name: 'Enrollment Code',
          key: 'enrollmentCode',
          valuePath: 'code',
          selected: false
        },
        {
          name: 'Classification',
          key: 'enrollmentClassification',
          valuePath: 'classification.classificationCode',
          selected: false
        },
        {
          name: 'TOS',
          key: 'tos',
          selected: false
        },
        {
          name: 'Withdrawal Date',
          key: 'withdrawalEndDate',
          valuePath: 'withdrawalEndDate',
          selected: false
        },
        {
          name: 'Withdrawal Code',
          key: 'withdrawalCode',
          valuePath: 'withdrawalCode',
          selected: false
        }
      ]
    },
    {
      name: 'Attendance',
      expanded: true,
      fields: [
        {
          name: 'Attendance Date',
          key: 'attendanceDate',
          valuePath: 'additionalInfo.attendance.attendanceDate',
          selected: false
        },
        {
          name: 'Attendance Type',
          key: 'attendanceType',
          valuePath: 'additionalInfo.attendance.attendanceType',
          selected: false
        },
        {
          name: 'Reporting Begin Date',
          key: 'reportingBeginDate',
          valuePath: 'additionalInfo.attendance.reportingBeginDate',
          selected: false
        },
        {
          name: 'Reporting End Date',
          key: 'reportingEndDate',
          valuePath: 'additionalInfo.attendance.reportingEndDate',
          selected: false
        }
      ],
    },
    {
      name: 'Class Assignment',
      expanded: true,
      fields: [
        {
          name: 'Local Class Number',
          key: 'localClassNumber',
          selected: false
        },
        {
          name: 'Course Code',
          key: 'courseCode',
          selected: false
        },
        {
          name: 'Class Type',
          key: 'classType',
          selected: false
        },
        {
          name: 'Enrollment Period Begin Date',
          key: 'enrollmentPeriodStartDate',
          valuePath: 'assignmentEnrollmentPeriodStartDate',
          selected: false
        },
        {
          name: 'Enrollment Period End Date',
          key: 'enrollmentPeriodEndDate',
          valuePath: 'assignmentEnrollmentPeriodEndDate',
          selected: false
        },
        {
          name: 'Class Begin Date',
          key: 'classStartDate',
          valuePath: 'classStartDate',
          selected: false
        },
        {
          name: 'Class End Date',
          key: 'classEndDate',
          valuePath: 'additionalInfo.classAssignment.classEndDate',
          selected: false
        },
        {
          name: 'Assignment Begin Date',
          key: 'assignmentStartDate',
          valuePath: 'assignmentStartDate',
          selected: false
        },
        {
          name: 'Assignment End Date',
          key: 'assignmentEndDate',
          valuePath: 'assignmentEndDate',
          selected: false
        },
        {
          name: 'Class Period Name',
          key: 'classPeriodName',
          valuePath: 'additionalInfo.classAssignment.classPeriodName',
          selected: false
        },
        {
          name: 'Class Period Duration',
          key: 'classPeriodDuration',
          valuePath: 'additionalInfo.classAssignment.classPeriodDuration',
          selected: false
        },
        {
          name: 'Class Period Meeting Days',
          key: 'classPeriodMeetingDays',
          valuePath: 'additionalInfo.classAssignment.classPeriodMeetingDays',
          selected: false
        }
      ],
    },
    {
      name: 'Classification',
      expanded: false,
      fields: [
        {
          name: 'Classification',
          key: 'classificationCode',
          valuePath: 'classification.classificationCode',
          selected: false
        },
        {
          name: 'Classification Begin Date',
          key: 'classificationStartDate',
          valuePath: 'classification.classificationDateStart',
          selected: false
        },
        {
          name: 'Classification End Date',
          key: 'classificationEndDate',
          valuePath: 'classification.classificationDateEnd',
          selected: false
        }
      ],
    },
    {
      name: 'Final Grade',
      expanded: false,
      fields: [
        {
          name: 'Credits Earned',
          key: 'creditsEarnedInput',
          valuePath: 'creditsEarned',
          selected: false
        },
        {
          name: 'Alpha Grade',
          key: 'alphaGradeInput',
          valuePath: 'alphaGrade',
          selected: false
        },
        {
          name: 'Numeric Grade',
          key: 'numericGradeInput',
          valuePath: 'numericGrade',
          selected: false
        },
        {
          name: 'Year Class was Taken',
          key: 'classSchoolYear',
          valuePath: 'additionalInfo.finalGrade.classSchoolYear',
          selected: false
        },
        {
          name: 'Term Class was Taken',
          key: 'classTerm',
          valuePath: 'additionalInfo.finalGrade.classTerm',
          selected: false
        },
        {
          name: 'Credits Attempted',
          key: 'creditsAttempted',
          valuePath: 'additionalInfo.finalGrade.creditsAttempted',
          selected: false
        },
        {
          name: 'Quality Points',
          key: 'qualityPoints',
          valuePath: 'additionalInfo.finalGrade.qualityPoints',
          selected: false
        }
      ]
    },
    {
      name: 'Special Ed Options',
      expanded: false,
      fields: [
        {
          name: 'Option Level',
          key: 'optionLevel',
          selected: false
        },
        {
          name: 'Option Number',
          key: 'optionNumberInput',
          valuePath: 'optionNumber',
          selected: false
        },
        {
          name: 'Begin Date',
          key: 'specEdBeginDate',
          selected: false
        },
        {
          name: 'End Date',
          key: 'specEdEndDate',
          selected: false
        }
      ]
    },
    {
      name: 'Special Ed Disabilities',
      expanded: false,
      fields: [
        {
          name: 'Disability Level',
          key: 'specEdDisabilityLevel',
          selected: false
        },
        {
          name: 'Disability Type',
          key: 'specEdDisabilityType',
          selected: false
        },
        {
          name: 'Begin Date',
          key: 'specEdDisabilityBeginDate',
          selected: false
        },
        {
          name: 'End Date',
          key: 'specEdDisabilityEndDate',
          selected: false
        }
      ]
    },
    {
      name: 'Standard AMA/ADA',
      expanded: false,
      fields: [
        {
          name: 'Report Period',
          key: 'standardReportPeriod',
          selected: false
        },
        {
          name: 'Student Standard ADM',
          key: 'standardStudentAdm',
          selected: false
        },
        {
          name: 'Student ADA',
          key: 'standardStudentAda',
          selected: false
        }
      ]
    },
    {
      name: 'Vocational ADM/ADA',
      expanded: false,
      fields: [
        {
          name: 'Report Period',
          key: 'vocationalReportPeriod',
          valuePath: 'vocationalInfo.reportingPeriod.periodNumber',
          selected: false
        },
        {
          name: 'Program Item',
          key: 'vocationalProgramItem',
          valuePath: 'vocationalInfo.programItem',
          selected: false
        },
        {
          name: 'Student ADM',
          key: 'vocationalStudentAdm',
          valuePath: 'vocationalInfo.studentAdm',
          selected: false
        },
        {
          name: 'Student ADA',
          key: 'vocationalStudentAda',
          valuePath: 'vocationalInfo.studentAda',
          selected: false
        }
      ]
    },
    {
      name: 'Disciplinary Actions',
      expanded: false,
      fields: [
        {
          name: 'Enrollment Period Start',
          key: 'disciplinaryEnrollmentPeriodStart',
          selected: false
        },
        {
          name: 'Enrollment Period End',
          key: 'disciplinaryEnrollmentPeriodEnd',
          selected: false
        },
        {
          name: 'Disciplinary Begin Date',
          key: 'disciplinaryPeriodBegin',
          selected: false
        },
        {
          name: 'Disciplinary End Date',
          key: 'disciplinaryPeriodEnd',
          selected: false
        },
        {
          name: 'Disciplinary Type',
          key: 'disciplinaryType',
          selected: false
        },
        {
          name: 'Disciplinary Reason',
          key: 'disciplinaryReason',
          selected: false
        },
        {
          name: 'Zero Tolerance',
          key: 'zeroTolerance',
          selected: false
        },
      ],
    },
    {
      name: 'End of Service',
      expanded: false,
      fields: [
        {
          name: 'End of Service Action Date',
          key: 'endOfServiceDate',
          valuePath: 'endOfService.endOfServiceDate',
          selected: false
        },
        {
          name: 'End of Service Action',
          key: 'eosAction',
          valuePath: 'endOfService.eosAction',
          selected: false
        },
        {
          name: 'Completion Document Date',
          key: 'compDocDate',
          valuePath: 'endOfService.compDocDate',
          selected: false
        },
        {
          name: 'Completion Document Type',
          key: 'compDocType',
          valuePath: 'endOfService.compDocType',
          selected: false
        },
        {
          name: 'Completion Document Period',
          key: 'compDocPeriod',
          valuePath: 'endOfService.compDocPeriod',
          selected: false
        }
      ]
    },
    {
      name: 'Ineligibility Funding Status',
      expanded: false,
      fields: [
        {
          name: 'Funding Ineligibility Status',
          key: 'fundingEligibility',
          valuePath: 'ineligibilityFundingStatus.status',
          selected: false
        },
        {
          name: 'Funding Ineligibility Begin Date',
          key: 'ineligibilityFundingBeginDate',
          valuePath: 'ineligibilityFundingStatus.beginDate',
          selected: false
        },
        {
          name: 'Funding Ineligibility End Date',
          key: 'ineligibilityFundingEndDate',
          valuePath: 'ineligibilityFundingStatus.endDate',
          selected: false
        }
      ]
    }
  ];